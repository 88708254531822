.bulk-actions {
       display: flex;
       align-items: end;
       flex-direction: column;
       gap: 0.5rem;
       font-family: "Inter", sans-serif;
       font-weight: 500;
       font-size: 12px;
    
      
}
.bulk-actions .clear-btn {
    cursor: pointer;
    color: var(--alpha-blue-back);
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
.bulk-actions .add-new-plus {
    width: 24px;
    height: 24px;
    background: var(--alpha-blue-back);
    border-radius: 50%;
    color: var(--alpha-white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-header.bulk-upload-header {
     align-items: start !important;
}

.store-card .card-details.bulk-card {
    height: 100% !important;
    min-height: 287px !important;
    max-height: 350px !important;
    padding-bottom: 1rem;
}
.bulk-card img {
    object-fit: cover;
    background: var(--alpha-10);
}
.store-card .card-details.bulk-card  .bulk-product-desc {
    font-family: "Circular Std", sans-serif;
    width: 90% !important;
    height: 120px !important;
    margin: 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: center !important;
    /* gap: 0.25rem; */

}
.store-card .card-details .bulk-product-desc div.title {
    width: 90%;
    font-weight: 450;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4D4D4D;
    font-size: 14px;
    color: #000000;
}
.store-card .card-details .bulk-product-desc h3 {
    font-weight: 500;
    font-size: 18px;
}
.store-card .card-details .bulk-product-desc div {
    width: 100% !important;
}
.store-card .card-details .bulk-product-desc  .verify-upload-tag {
    width: 100% !important;
    display: flex;
    align-items: center;
    gap: 0.15rem;
    /* margin-top: -4px !important; */
}
 .verify-upload-tag > .check-upload {
     width: 15px !important;
     height: 15px !important;
     border-radius: 50%;
     background: #00B412;
     color: var(--alpha-white);
     display: flex;
     align-items: center;
     justify-content: center;
}

.store-card .card-details .bulk-product-desc  .verify-upload-tag > span:last-child {
     color: #00B412;
     font-family: "Circular Std", sans-serif;
     font-size: 14px;
}
.bulk-card .edit-delete-action {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn__submit-load {
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.btn__submit-load .loader-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.upload-success-section  {
    width: 95%;
    margin: auto;
    height: 100%;
    padding: 2rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

/* styles.css */
.outer-div {
    position: relative;
    display: inline-block;
  }
  
  .outer-div::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Adjust color and opacity as needed */
    border-radius: 50%;
    transform: translate(-50%, -50%) 
    scale(1);
    transform-origin: center;
    /* z-index: -1; */
    animation: puff 2s infinite;
  }
  
  @keyframes puff {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
  
.upload-success-section .success-check {
     width: 70px;
     height: 70px;
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
}  

.upload-success-section .success-check .check-mark {
     width: 50px;
     height: 50px;
     background: var(--alpha-success-dark);
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     color: var(--alpha-white);
}

.upload-success-section .upload-notes-section {
       display: flex;
       flex-direction: column;
       align-items: center;
       gap: 2rem;
    
}

.upload-success-section .upload-notes-section .success-text-sec {
     width: 95%;
     margin: auto;
     font-family: "Inter", sans-serif;
}
.upload-success-section .upload-notes-section .success-text-sec h3 {
      font-weight: 700;
      color: #262641;
      font-size: 20px;
}
.upload-success-section .upload-notes-section .success-text-sec p {
    font-weight: 500;
    font-size: 14px;
    color: #9797AA;
}

.upload-success-section .go-home {
    width: 200px;
    height: 50px;
    background: var(--alpha-blue-back);
    border-radius: 10px;
    border: 1px solid var(--alpha-blue-back);
    color: var(--alpha-white);
    font-weight: 700;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}


.upload-success-card {
    max-width: 1033px;
    margin: 0 auto;
    display: grid;
    place-items: center !important;
    gap: 1rem;
}
.upload-success-card img {
     width: 238px;
     height: 235px;
}
.upload-success-card .single-detail a, .upload-success-card .single-detail button {
    width: 238px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid var(--alpha-80);
    background: var(--alpha-white);
    color: #262641;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}
.upload-success-card .single-detail  {
     display: flex;
     flex-direction: column;
     gap: 10px;
}
@media (min-width: 600px) {
    .upload-success-card { 
        grid-template-columns: repeat(2, minmax(min(200px, 258px), 1fr));
    }
}

@media only screen and (min-width: 48rem) {
    .bulk-actions {
        align-items: center;
        flex-direction: unset;
        gap: 0.75rem;
     
       
    }
    .upload-header.bulk-upload-header {
        align-items: center !important;
    }
    .upload-success-section .upload-notes-section .success-text-sec {
        width: 375px;
    }
    .upload-success-section .upload-notes-section .success-text-sec h3 {
        font-size: 24px;
    }
    .upload-success-card { 
        grid-template-columns: repeat(3, minmax(min(200px, 258px), 1fr)); 
    }
}

@media only screen and (min-width: 80em){
    .upload-success-section {
          width: 1033px;
    }
    .upload-success-card { 
        grid-template-columns: repeat(4, minmax(min(200px, 258px), 1fr)); 
    }
}