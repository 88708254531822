.video-modal {
     position: fixed;
     background: rgba(25,33,80, 0.9);
     width: 100vw;
     height: 100vh;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
     z-index: 10000;
     cursor: pointer;
     
}
.video-card {
      position: relative;
      width: 100%;
      height: 70%;
      min-height: 0;
      max-width: 600px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
}


.video-card.show-modal {
     min-height: 200px;
     height: 100% !important;
    
}
.video-image {
     display: block;
     box-sizing: border-box;
     color: #fff;
     background-color: var(--alpha-10);
     width: 100% !important;
     height: 100% !important;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
}

video.video-image-desktop {
     display: block;
     box-sizing: border-box;
     color: #fff;
     background-color: var(--alpha-10);
     width: 100% !important;
     height: 100% !important;
     
}


video {
     object-fit: contain !important;
     width: 100% !important;
}
.close-video {
     position: absolute;
     left: 1%;
     top: 2%;
     font-size: 24px;
     color: var(--alpha-white);
     transition: color 0.3s ease;
     z-index: 1000000;
     border: 2px solid var(--alpha-80);
     height: 2rem;
     width: 2rem;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     background: var(--alpha-80);
  
}

 .close-video:hover {
    color: var(--alpha-red);
}
.video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; 
    transform: translate(-50%, -50%);
    
  }
  .video-wrapper.hide-player {
      display: none;
  }
  


  @media only screen and (min-width: 768px)  {
     .close-video {
          top: 1%;
          left: 2%;
     }
      .video-wrapper {
          height: 100%;
          
        }
      
  }