
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@1,200&display=swap');

@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std'), url("./fonts/circular_std_book_3.ttf") format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque.otf") format('opentype');
  font-size: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Italic.otf") format('opentype');
  font-size: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Hairline.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Hairline\ Italic.otf") format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Thin.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Thin\ Italic.otf") format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Extralight.otf") format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Extralight\ Italic.otf") format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: fallback;
}


@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Light.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Light\ Italic.otf") format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Medium.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Medium\ Italic.otf") format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Semibold.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Semibold\ Italic.otf") format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Bold\ Italic.otf") format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Bold\ Italic.otf") format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Extrabold.otf") format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Rebond Grotesque';
  src: local('Rebond Grotesque'), url("./fonts/Rebond\ Grotesque\ Extrabold\ Italic.otf") format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}

:root {
  --alpha-primary: #0480fc;
  --alpha-success: #27ae60;
  --alpha-success-dark: #00B412;
  --alpha-white: #ffffff;
  --alpha-bg-color: #F7FBFF;
  --alpha-img-color: rgba(238,238,238,255);
  --alpha-blue-back: #192150;
  --alpha-blue-back-overlay: rgba(25,33,80,0.5);
  --alpha-blue-back-25: rgba(25,33,80,0.25);
  --alpha-10:  rgba(51, 51, 51, 0.1);
  --alpha-20:  rgba(51, 51, 51, 0.2);
  --alpha-40: rgba(51, 51, 51, 0.4);
  --alpha-80: rgba(51, 51, 51, 0.8);
  --alpha-white-alt: #F5F5F5;
  --alpha-body-color: #E5E5E5;
  --alpha-green: #72FFC9;
  --alpha-lemon: #C2D068;
  --alpha-yellow: #FFC107;
  --alpha-alternative-green: #849614;
  --alpha-black: #000000;
  --alpha-red: #ED3237;
  --alpha-desktop-bg: #f7fffc;
  --alpha-verified-bg: rgba(248, 224, 89, 0.15);
  --alpha-seamless-bg: rgba(251, 155, 155, 0.15);
  --alpha-secure-bg: rgba(90, 156, 254, 0.15);
  --alpha-warning-100: rgba(254, 173, 52, 0.15);
  --alpha-info-100: rgba(89, 191, 248, 0.15);
  --alpha-danger-100: rgba(251, 155, 155, 0.15);
  --alpha-success-100: rgba(129, 180, 22, 0.15);
  --alpha-shadow-sm: 0px 4px 4px rgba(0,0,0,0.2);
 

}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: var(--alpha-white) !important;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}
.green-dot {
  width:6px;
  height: 6px;
  background: #7DC40A;
  border-radius: 50%;
}
.green-dot_big {
     width: 10px;
     height: 10px;
     background: #72FFC9;
     border-radius: 50%;
}
.something__went-wrong {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.something__went-wrong div {
    color: var(--alpha-blue-back);
    font-size: 1.5rem;
    font-weight: normal;
    font-family: "Circular Std", sans-serif;
}
.something__went-wrong button {
    width: 100px;
    height: 40px;
    background: var(--alpha-blue-back);
    color: var(--alpha-white);
    font-size: 1rem;
    font-family: "Circular Std", sans-serif;
    border: 1px solid var(--alpha-blue-back);
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0,0,0,0.25)
}
body.modal-open {
  overflow: hidden; 
}
.bg-warning-100 {
  background: var(--alpha-warning-100) !important;
}
.bg-info-150 {
     background: var(--alpha-secure-bg) !important;
}
.bg-info-100 {
    background: var(--alpha-info-100) !important;
}
.bg-success-100 {
  background: var(--alpha-success-100) !important;
}
.bg-danger-100 {
  background: var(--alpha-danger-100) !important;
}
.cursor-pointer {
  cursor: pointer;
}
body::-webkit-scrollbar{
  width: .5em;
} 
.modals-container-pickup::-webkit-scrollbar, .desktop__checkout-modal.card::-webkit-scrollbar, .desktop__doordelivery_details-modal.card::-webkit-scrollbar,.desktop__checkout_pickup-modal.card::-webkit-scrollbar, .modals-container::-webkit-scrollbar,.desktop-prod-details-col::-webkit-scrollbar,.desktop__payment_details-modal.card::-webkit-scrollbar, .card__info .product__info_container::-webkit-scrollbar, .home-page-alpha-search .alpha__filtered-items::-webkit-scrollbar {
     width: 3px !important;
}
 
body::-webkit-scrollbar-track, .modals-container-pickup::-webkit-scrollbar-track, .desktop__doordelivery_details-modal.card::-webkit-scrollbar-track, .desktop__checkout-modal.card::-webkit-scrollbar-track,.desktop__checkout_pickup-modal.card::-webkit-scrollbar-track ,.modals-container::-webkit-scrollbar-track,.desktop__payment_details-modal.card::-webkit-scrollbar-track, .card__info .product__info_container::-webkit-scrollbar-track, .home-page-alpha-search .alpha__filtered-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--alpha-blue-back);
}
.desktop-prod-details-col::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--alpha-40);
}
 
body::-webkit-scrollbar-thumb, .modals-container-pickup::-webkit-scrollbar-thumb, .modals-container::-webkit-scrollbar-track, .desktop__checkout-modal.card::-webkit-scrollbar-thumb, .desktop__checkout_pickup-modal.card::-webkit-scrollbar-thumb, .desktop__doordelivery_details-modal.card::-webkit-scrollbar-thumb,.desktop__payment_details-modal.card::-webkit-scrollbar-thumb,.card__info .product__info_container::-webkit-scrollbar-thumb, .home-page-alpha-search .alpha__filtered-items::-webkit-scrollbar-thumb {
  background-color: var(--alpha-green);
  outline: 1px solid var(--alpha-alternative-green);
  border-radius: 8px;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--alpha-blue-back);
  transform-origin: 0%;
  z-index: 1500;
}
.slide-in {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: #0f0f0f;
   transform-origin: bottom;
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: top;
}

.related__product_view-more {
     display: flex;
     justify-content: center;
     align-items: center;
     text-decoration: none;
     font-weight: 400;
     font-family: "Inter", sans-serif;
     margin-top: 1.5rem;
     margin-left: auto;
     margin-right: auto;
     font-size: 1.2rem;
     width: 95%;
     height: 50px;
     background: transparent;
     border-radius: 5px;
     border: 1.5px solid #F1F1F1;
     color: #979797 !important;
     transition: all 0.3s ease;
}
.related__product_view-more:hover {
    background: var(--alpha-green);
     color: var(--alpha-blue-back) !important;
     border-color: var(--alpha-green);
}
.desktop-prod-details-col::-webkit-scrollbar-thumb {
  outline: 1px solid var(--alpha-10);
  border-radius: 5px;
  background-color: var(--alpha-80);
}
.tracker-input::-webkit-input-placeholder { 
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
    padding-left: 15px !important;
}
.tracker-input::-moz-placeholder { 
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
    padding-left: 15px !important;
}
.tracker-input:-ms-input-placeholder { 
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
    padding-left: 15px !important;
}
.tracker-input:-moz-placeholder { 
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
    padding-left: 15px !important;
}
.tracker-input::placeholder {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
    padding-left: 15px !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="radio-button"] {
   accent-color: var(--alpha-blue-back) !important;
}
input.delivery__checkbox-pickup {
     width: 1rem;
     height: 1rem;
     border: 2px solid var(--alpha-blue-back) !important;
     border-radius: 50%;
     accent-color: var(--alpha-blue-back) !important;
}
.navigation-bar {
     width: 100%;
     height: 70px;
     background:var(--alpha-white);
     position: sticky !important;
     top: 0;
     display: flex;
     align-items: center !important;
     justify-content: space-between;
}
.navigation-items {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  width: 100%;
  padding: 0 1rem;
  justify-content: space-between !important;
}
.navigation-items .search {
   position: relative;
   display: none !important;
}
.navigation-items .logo {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.user-auth {
    display: none;
    align-items: center;
    gap: 30px;
}
.user-auth .user-login {
  display: flex;
  align-items: center;
  color:rgba(51, 51, 51, 0.8);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  gap: 5px;
}
.user-auth .cart-icon {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #F5F5F5;
    position: relative;
    cursor: pointer;
   
}
.user-auth .cart-icon > .shopping-cart-icon {
  font-size: 16px;
}
.user-auth .cart-icon span {
    position: absolute;
    color: var(--alpha-blue-back) ;
    font-size: 12px;
    padding: 2px;
    top: -4px;
    left: 10px;
    background: var(--alpha-green);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;


}

.user-auth > button {
  background: var(--alpha-green);
  width: 60px;
  border-radius: 8px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
}

#auth_popup {
      position: absolute;
      right: 1rem;
      width: 200px;
      height: 194px;
      top: 4rem;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      z-index: 1000000;
      transition: .3s ease-in-out;
}

#auth_popup .sign-in-btn {
   width: 80%;
   margin: 1rem auto;
  
}
#auth_popup .sign-in-btn button {
    width: 100%;
    height: 41px;
    background: var(--alpha-green);
    border-radius: 5px;
    border: none;
    outline: none;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--alpha-blue-back);
}

#auth_popup > .auth-divider {
  height: 0px;
  width: 100%;
  border-bottom: 1px solid var(--alpha-10);
}
#auth_popup > .account_details  {
    width: 80%;
    margin: 1rem auto;
    
}


#auth_popup > .account_details div {
       line-height: 25px;
      font-family: "Circular Std", sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      letter-spacing: 1.25px;
      color: var(--alpha-blue-back);
      display: flex;
      align-items: center;
      gap: 30px;
      cursor: pointer;
}
.navigation-items .logo img {
  width: 60px;
}

.search input:focus {
    outline: none;
}

.valid {
    border-color: #36cc36 !important;
}
.invalid {
  border-color: orange !important;
}
.input-text + p, .input-text-mobile + p  {
  font-family: "Inter",sans-serif;
  font-size: .8em;
  font-weight: bold;
  text-align: center;
  margin: 0px 10px;
  color: orange;
  opacity: 0;
  height: 0;
}

input.invalid + p {
   opacity: 1;
   height: auto;
}

.search .search-icon {
  position: absolute;
  z-index: 10000;
  top: 50%;
  transform: translateY(-50%);
  left: .5rem;
  color: var(--alpha-40) !important;
}

.footer-bar {
  width: 100%;
  background: var(--alpha-blue-back);
  padding: 1.5rem 1rem;

}
.footer-container {
     width: 100%;
     display: none;
}
.footer-header {
    width: 69px;
    height: 22px;
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: var(--alpha-white-alt);

}
.hide-on-desktop {
    display: block;
}
.product__detail-container {
    width: 95%;
    margin: 1rem auto;
}

.product__detail-container .back-btn {
     display: flex;
     align-items: center;
     gap: 0.5rem;
     color: var(--alpha-blue-back);
     font-family: "Inter", sans-serif;
     font-size: 14px;
     font-weight: 600;
     cursor: pointer;
}

.footer-items {
    margin-top: .5rem;
    min-height: 200px;
    display: flex;
    flex-direction: column;

}
.footer-items-mobile {
   margin-bottom: 1rem !important;
}
.footer-items span:nth-child(n+2) {
    margin-top: .5rem;
}

.footer-items span a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: var(--alpha-white-alt);
  text-decoration: none;

  
}
.footer-items span a:hover {
    color: var(--alpha-white)
}

.footer-hr {
     color: #FFFFFF !important;
     opacity: 0.35;
     display: none;
}
.social-cover{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-policy{
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.1px;
    color: var(--alpha-white);
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: .2rem;
}

.footer-policy a{
  color: var(--alpha-white);
  text-decoration: none;
}

.footer-snippet {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.1px;
    color: var(--alpha-white);
    padding-top: 1rem;
}
.back-navigation {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     margin: auto
}
.back-btn-navigate {
  border: none;
  color: var(--alpha-white);
  background: var(--alpha-blue-back);
  padding: .5rem 1rem;
  font-size: 10px;
  font-family: "Circular Std",sans-serif;

}
.product-card {
    width: 100%;
    height: unset;
    background: var(--alpha-white) !important;
    padding:  0 !important;
    

}
.mobile-display {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF !important;

}
.location-address-section {
    display: flex;
    height: 100%;
    width: 100% !important;
    align-items: flex-start;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
}
.location-address-section div {
     font-family: "Inter",sans-serif;
     font-size: 0.7em;
     color: var(--alpha-40);
}
.location-address-section div:nth-child(1){
    color: var(--alpha-blue-back);
    font-weight: 700;
}
.product-name-location {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 .mobile-display .swiper.image-slider {
  background: var(--alpha-white) !important;
  border-radius: 0px 0px 30px 30px !important;
  margin-top: 0;
  width: 100% !important;
  height: 60vh !important;
  border-bottom: 3px solid var(--alpha-body-color);
 
 
}
.swiper-slide.current-image {
  width: 100%;
  height: 100% !important;
  position: relative;
 
}
.swiper-slide.current-image img, .swiper-slide.current-image .video-react{
   width: 100%;
   border-radius: 0px 0px 30px 30px;
   height: 100% !important;
   margin-top: 0;
   object-fit: cover;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%);
   
}

.mobile-display .swiper-pagination > .swiper-pagination-bullet {
    background-color: var(--alpha-green);
    width: 12px;
    height: 12px;
}

.mobile-display .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active{
     border: 1px solid var(--alpha-blue-back);
}

.paginated-radio {
  width: 12px !important;
  height: 12px !important;
  background-color: var(--alpha-green);
  border-radius: 50%;

  
}
.active-page {
    border: 1px solid var(--alpha-blue-back);
}
.paginated-radio:nth-child(n+2){
    margin-left: .2rem;
}
.no-reviews-yet {
  padding: 1rem;
  color: var(--alpha-blue-back);
}
.mobile-page-content {
   padding: .5rem 1rem 1rem 1rem;
   margin-top: 1rem;
}
.animated_image {
  animation-name: animate_image;
  animation-duration: 4s;
}

.terms-condition-container {
    padding: 1.2rem 0px;
    width: 95%;
    margin: auto !important;
}

.terms-condition-container h1{
  font-family: "Cirular Std",sans-serif;
  text-align: center;
}

.terms-condition-container .terms-hr {
   width: 100%;
   height: .7px;
   margin-bottom: 10px;
   background: var(--alpha-blue-back) !important;
}
.terms-condition-container .terms-content {
   font-family: "Circular Std",sans-serif;
}

.pre-requisite-setion {
    list-style: none;
}
.pre-requisite-setion-alpha {
     list-style-type: lower-alpha;
}
.mobile-page-content .alpha-code {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;  
  color: var(--alpha-blue-back);
  display: flex;
  align-items: center;
  gap: 0.4em;
  cursor: pointer;
}
.share-product-mobile {
     cursor: pointer;
     font-size: 1rem;
     font-weight: 900;
     color: var(--alpha-blue-back);
     margin-top: -7px;
}

@counter-style dash {
  system: cyclic;
  symbols: " \2212";
  suffix: " ";
}
.privacy-info-list {
     list-style: dash;
}

@keyframes animate_image {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
}


.mobile-page-content .product-name-for-mobile {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 25px;
    color: var(--alpha-blue-back);  
}

.mobile-page-content .product-price-for-mobile {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;  
    color: var(--alpha-40);
}
.mobile-page-content .product-price-for-mobile.discounted-price {
     font-size: 0.9rem;
     font-weight: 600;
     color: var(--alpha-20);
     text-decoration: line-through;
     text-decoration-color: var(--alpha-red);
}

.pay-button-on-mobile-device button {
  height: 50px;
  background: var(--alpha-green);
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--alpha-green);
  outline: none;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1px;
  color: var(--alpha-blue-back);
}
.mobile-device-store-name {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center !important;
   padding: 10px 15px;
   margin: 5px 0;
   border-top: 1px solid #EFEFEF;
   border-bottom: 1px solid #EFEFEF;
}
.mobile-device-store-name .mobile-display-store-image{
  width: 40px;
  height: 40px;
  padding: 2px;
  background: #EFEFEF;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-device-store {
     margin: 0px 0px -10px 10px;
}
.mobile-device-store-name .mobile-display-store-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.mobile-device-store div{
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 14px;  
  color: var(--alpha-40)
}
.mobile-device-store h5 {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--alpha-blue-back);
}
.mobile-device-item-description {
   padding: 10px 0px;
   height: 100%;
  
}
.mobile-device-item-description .tab-item {
     display: flex;
     gap: 10rem;
     width: 100%;
     height: 100%;
     justify-content: center;
     align-items: center;
     padding: .5rem 0 1rem 0;
     border-bottom: 1px solid var(--alpha-body-color);
}
.product-tab {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(51, 51, 51, 0.4);  
  transition: all .5s ease-in-out;
  cursor:pointer
}
.active-prod-tab {
  color: var(--alpha-blue-back);
  position: relative;

}
.active-prod-tab::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 8rem;
  display: block;
  left: 50%;
  top: 1.07rem;
  z-index: 100;
  border-bottom: 2px solid var(--alpha-blue-back);
  transform: translateX(-50%);
  transition: all .8s ease-in;
  
}
.mobile-device-item-description .product-description-det {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 14px;
  color: var(--alpha-80);
  padding: 1rem;
  transition: all .7s ease-in;
}
.mobile-app-download {
  width: 100%;
  height: 160px;
  padding: 10px;
  position: relative;
  margin: 0 auto 1rem auto;
}

.app-download-container {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #41BF8F, #41BF8F);
      border-radius: 6px;
      position: relative;
    
}
.app-download-container .pixel-one{
     position: absolute;
     left: 0.5em;
     top:  .9em;
     height: 94%;
     width: 4.5em;
}
.app-download-container .pixel-two {
  position: absolute;
  left: 5.2em;
  top:  1.45em;
  height: 87%;
  width: 4.5em;
}
.gig-alpha-download{
    position: absolute;
   text-align: center;
   width: 100%;
   height: 100%;
   right: 1rem;
   top: .8rem;
} 

.gig-alpha-download h4 {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    color: var(--alpha-white);
    width: 100%;
}
.gig-alpha-download p {
  position: absolute;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: var(--alpha-white);
  width: 35%;
  right: 1rem;
}
.gig-alpha-download div {
  position: absolute;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  color: var(--alpha-white);
  width: 35%;
  right: 1rem;
  margin-top: 2.5rem;
}

.download-icons-mobile {
    width: 100%;
    display: inline-flex;
   position: absolute;
   margin-right: 1.5rem;
   bottom: 1rem;
}

.mobile-download-appstore {
  width: 10em;
  height: 100%; 
  margin-left: 5px;
}
.mobile-download-playstore {
      width: 10em;
      height: 100% !important;
    
}
.scroll-border {
    color: #EFEFEF;
    opacity: 0.5;
}

.scroll-arrow-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.scroll-to-top .social-header {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;  
  color: var(--alpha-white);
  text-align: center;
  margin: .5rem auto;
}
.scroll-to-top .social-icons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--alpha-white);
    font-size: 14px;
}

.price-section-mobile {
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.scroll-to-top .social-icons .icon a {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 50%;
    color: var(--alpha-black);
    background: var(--alpha-bg-color);
}

.desktop-display {
    display: none;
}
.review-section {
    width: 100%;
    height: 100%;
    display: none;
}
.desktop-review-container {
     width: 100%;
     height: 100%;
     padding-bottom: 1.5rem !important;
     margin: auto;
}
.review-header {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;  
    letter-spacing: 0.25px;
    text-transform: uppercase;  
    color: var(--alpha-blue-back);
}
.review-row hr {
  border: .5px solid var(--alpha-10)
}

.gig-alpha-download-desktop {
    display: none;
}
.product-card-details {
    width: 95% !important;
    margin: auto;
}

.card-image {
  width: 100%;
  position: relative;
  max-height: 250px;
  min-height: 250px;
  height: 100%;
  background: var(--alpha-white);
  border: transparent !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-self: flex-start;
}
.card-image img, .card-image .video-react {
     width: 100% !important;
     height: 100% !important;
     border-radius: 5px;
     position: absolute;
}
.image-thumbnails {
  width: 40px !important;
  height: 40px !important;
}
.rounded {
  background: #FFFFFF;
  border-radius: 5.5748px;
  border: 0.929134px solid var(--alpha-white-alt);
}
.active {
    box-sizing: border-box;
    border-radius: 5.5748px;
    background: #FFFFFF;
    border: 0.929134px solid #849614;
    box-sizing: border-box;

}
.thumbnail-images-styles {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    height: 100%;
}
.right-arrow {
  color: var(--alpha-40);
  border: none;
  background: var(--alpha-white);
  outline: none;
  font-weight: bolder;
}
.product-name {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  margin-top: 4rem;
  letter-spacing: -0.743307px;  
  color: var(--alpha-blue-back) !important;
}

.brand-img {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  border: 1px solid var(--alpha-40);
}

.store-name-placeholder {
    display: flex;
    align-items: center;
     width: 100%;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.75px;
    margin-top: -0.5rem;
}
.reviews-n-store-name {
    max-width: 464px;
    word-wrap: break-word;
    margin-top: initial;
    
}
.store-desc {
   font-weight: bold;
   color: var(--alpha-blue-back);
   width: 95%;
   font-size: 14px;
   font-family: "Circular Std",sans-serif;
}
.store-name {
  color: var(--alpha-blue-back);
  opacity: 0.65;
  width: 25%;
}
.single-item-hr {
  color: 0.5px solid rgba(194, 208, 104, 0.4);
}
.qty-price {
    display: flex;
    align-items: center;
}
.check-available {
    margin: 0.5rem 0;
}
.check-available div:first-child {
     display: flex;
     align-items: center;
     gap: 2rem;
}
.is-available {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.75px;
  color: #757575;
}
.available-size-desktop{
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
}
.available-size-desktop > .sizes-desktop {
    width: 25%;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;  
    letter-spacing: 0.75px;
    color: #757575;
}

.sizes-select-desktop{
  width: 75% !important;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
    
}
.sizes-select-desktop::-webkit-scrollbar { 
  display: none;
}
.sizes-select-desktop button {
    min-width: 30px !important;
}
.prefered-color-desktop {
   display: flex;
   align-items: center;
   width: 100%;
   gap: 1%;
   margin: 0.5rem 0;
   
}
.prefered-color-desktop .prefer-desktop {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;  
  letter-spacing: 0.75px;
  color: #757575;
  width: 14rem;

}

.btn-minus {
  background: var(--alpha-green);
  border: 1px solid var(--alpha-green);
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: .3rem .8rem;
  color: var(--alpha-blue-back);
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}
.btn-plus  {
  background: var(--alpha-green);
  border: 1px solid var(--alpha-green);
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: .3rem .8rem;
  color: var(--alpha-blue-back);
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}
.item-add-disabled {
  background: var(--alpha-white-alt) !important;
  border: 1px solid var(--alpha-white-alt) !important;
  color: gray;
} 
.item-add-desktop-disabled {
  background: var(--alpha-10) !important;
  color: var(--alpha-10);
  display: flex;
  align-items: center;
  justify-content: center;
} 
.btn-qty {
  background: var(--alpha-white);
  border: 1px solid var(--alpha-green);
  box-sizing: border-box;
  padding: .3rem .8rem;
  color: var(--alpha-blue-back);
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}
.qty-price .price {
  width: 75%;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: var(--alpha-blue-back);
}
.qty-price div:first-child {
  width: 25%;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.75px;
  color: #757575;
}
.add-qty {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: var(--alpha-blue-back);
  margin-right: .4rem;
}
.prefered-color {
    margin-bottom: 1rem;
}
.prefered-color .prefer {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: var(--alpha-blue-back);
}
.available-sizes {
    width: 100%;
}

.available-sizes .sizes {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: var(--alpha-blue-back);
}
.available-sizes .sizes .back-to-home, .available-sizes .sizes-home .back-to-home {
    font-size: 16px !important;
    margin-right: 1rem;
    font-weight: bolder !important;
    color: var(--alpha-blue-back) !important;
    cursor: pointer;
}
.sizes-select {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.sizes-select::-webkit-scrollbar { 
  display: none;
}
.selected-size-btn:nth-child(n+2), .selected-active-size-btn:nth-child(n+2){
     margin-left: .5rem !important;
}


.selected-size-btn, .selected-active-size-btn {
    border-radius: 2px;
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(21, 44, 67, 0.5);
    background: transparent !important;
}
.selected-size-btn {
   border: 1px solid var(--alpha-body-color);
   min-width: 30px !important;
   min-height: 30px;
   font-size: 10px;
   transition: all .3s ease-in-out;
}
.selected-active-size-btn {
  border: 1px solid var(--alpha-blue-back) !important;
  transition: all .3s ease-in-out;
  width: auto !important;
  min-width: 30px !important;
  min-height: 30px;
  box-sizing: content-box;
}

.qty-btn-section .add-remove-qty {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
}
.qty-btn-section > .add-remove-qty .btn-qty-desktop {
    border: none;
    background: none;
    color: var(--alpha-blue-back);
}
.qty-btn-section > .add-remove-qty > .btn-minus-desktop, .qty-btn-section > .add-remove-qty > .btn-plus-desktop {
    background: var(--alpha-blue-back);
    color: var(--alpha-white);
    width: 30px;
    height: 30px;
    font-size: 18px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;

}
.desktop-price {
     display: flex;
     align-items: center;
     gap: 1rem;
}
.desktop-price-section > div:first-child{
  width: 25%;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.75px;
  color: #757575;
}
.desktop-price-section > div:last-child {
  width: 75%;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;  
  color: var(--alpha-blue-back);
}
button:disabled {
    cursor: not-allowed !important;
}
.react-tooltip {
    max-width: 420px;
    min-width: 250px;
    overflow-wrap: break-word;
}

.all-trends {
    position: relative;
}
.all-trends .recent-sold {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  color: #000000;
  margin-left: 1rem;
}
.all-trends::before {
  content: "";
  height: 24px;
  border-left: 3px solid var(--alpha-lemon);
  position: absolute;
  left: 1rem;

}
.card-img-top {
  width: 200px !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF !important;
}
.all-cards {
  background: #FFFFFF !important;
}
.card-trendings {
  background: #FFFFFF !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px 7px rgba(121, 121, 121, 0.0527344);
  border-radius: 4px;
}
.checkout-btn button {
    width: 100%;
    height: 50px;
    background: var(--alpha-green);
    border-radius: 10px;
    border: 1px solid var(--alpha-green);
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: var(--alpha-blue-back);
}
.checkout-btn-disabled button {
    background: var(--alpha-white-alt) !important;
    border: 1px solid var(--alpha-white-alt) !important;
    color: rgba(0,0,0,0.4) !important
}

.cart__buynow-btn {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.checkout-btn-desktop, .buynow-btn-desktop {
    width: 50%;
}
.desktop__product-description {
    width: 100%;
    margin: 0.5rem 0;
    font-weight: 400;
    font-size: 14px;
    font-family: "Inter",sans-serif;
    line-height: 20px;
    color: #545454;
}
.description_readmore {
    cursor: pointer;
    color: var(--alpha-blue-back);
    font-weight: 700;
}
.checkout-btn-desktop button{
  width: 100%;
  height: 50px;
  background: var(--alpha-blue-back);
  color: var(--alpha-white);
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 1.25px;
  cursor: pointer;
  letter-spacing: 0.25px;
}
.buynow-btn-desktop button {
  width: 100%;
  height: 50px;
  background: var(--alpha-green);
  color: var(--alpha-blue-back);
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.25px;
}
.trending-items {
   width: 100%;
   background: var(--alpha-white);
   margin-top: .5rem;
   margin-bottom: .5rem;
   padding: 1rem 0;
}

.all-trends {
   padding: 1rem 5rem;
   margin-left: 1rem;
}
.card-title {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4D4D4D;
}

.card-text {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #333333;
}
.alpha-code {
     color: var(--alpha-alternative-green);
}
.alpha-code .copy-alpha-code-mobile{
  color: var(--alpha-40) !important;
}
.alpha-code-desktop {
     display: flex;
     height: auto;
     align-items: center;
     gap: 0.5em;
     cursor: pointer;
     margin: 0.5rem 0;
}
.alpha-code-desktop span:nth-child(1){
    color: #969696;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    line-height: 16.72px;
    font-size: 14px;
    letter-spacing: -0.09px;
}
.alpha-code-desktop span:nth-child(2){
  color: var(--alpha-blue-back);
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 16.72px;
  font-size: 14px;
  letter-spacing: -0.09px;
}
.alpha-code-desktop .copy-alpha-code {
    color: var(--alpha-40) !important;
    position: relative;
    font-size: 12px;
  
}
.alpha-code-desktop .copy-alpha-code.copy-active, .alpha-code .copy-alpha-code-mobile.copy-active {
     color: var(--alpha-success) !important;
}

.copied-text{
  font-size: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;

}
#error-location {
     display: flex;
     flex-direction: column;
     gap:1em;
     margin: 1em auto;
     font-family: "Circular Std",sans-serif;
     color: var(--alpha-80);
     width: 90%;
}
#error-location button.back-btn-navigate-address-two {
     width: 70%;
     height: 40px !important;
     border-radius: 8px;
     background: var(--alpha-10);
     color: var(--alpha-80);
     border: 1px solid var(--alpha-40) !important;
}
#error-location button.back-btn-navigate-address-one {
  width: 70%;
  height: 40px !important;
  border-radius: 8px;
  color: var(--alpha-80);
  background: var(--alpha-green);
  border: 1px solid var(--alpha-green) !important;
}
.viewed-price {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #333333;
}
.showcase {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 412px);
    height: 100%;
    background: var(--alpha-white);
}

.overlay {
    width: 100%;
    height: 120%;
    position: fixed;
    background-color: var(--alpha-blue-back) !important;
    top: 0;
    left: 0;
    opacity: 0.8;
    z-index: 3000;
    cursor: pointer;
    display: none;
}

.mobile-overlay {
  width: 100%;
  height: 120%;
  pointer-events: none;
  position: fixed !important;
  background-color: var(--alpha-blue-back);
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 3000;
  cursor: pointer;
}
.hide-overlay {
    display: none !important;
}

  .default-modal {
       top: 35% !important;
  }

.door-delivery-modal {
  top: 35% !important;
}

.modals-container {
     height: 480px !important;
     position: fixed !important;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     z-index: 100000;
     display: none;
     overflow-x: hidden;
     overflow-y: auto;
     
}
.success-cancel {
  position: relative;
  top: 0;
  right: 0;
}
.success-modal {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 100000;
  display: none;
}

.modals-container-payment {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  width: 40%;
  
}

.modals-container-hide {
  display: none;
}
.modals-container-pickup {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 100000;
  overflow-x: hidden;
  z-index: 100000;
  height: auto;
  max-height: 32rem !important;
  
}
dialog {
   overflow-x:  hidden !important;
   position: fixed !important;
}
dialog::backdrop {
     background: rgba(0,0,0,0.75);
}
dialog.desktop__checkout_pickup-modal.card {
  height: 100% !important;
  max-height: 32rem;
  padding: 0;
}
.desktop__checkout-modal.card {
  max-height: 32rem !important;
  height: 400px;
  padding: 0 !important;
  padding-bottom: 1rem !important;
}
.desktop__success-modal.card {
      height: 100% !important;
      max-height: 32rem;
      overflow: hidden; 
}
.desktop__doordelivery-modal.card {
   height: 480px !important;
}
.desktop__doordelivery_details-modal.card {
  max-height: 450px !important;
  min-height: 400px !important;
  padding: 0 !important;
}
.desktop__payment_details-modal.card {
   max-height: 560px !important;
   min-height: 550px !important;
   padding: 0 !important;
} 
.desktop__payment_details-modal.card .payment-methods {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
}

.paystack__and_cod-payment.payment-desk {
  margin-top: -1rem;
  cursor: pointer;
}

.card-modal-content {
    width: 100%;
    height: auto;
    background: var(--alpha-white);
    border-radius: 5px;
    padding-bottom: 3rem;
    box-shadow: 1px 1px var(--alpha-blue-back);
}

.loader-page {
    width: 200px;
    background: var(--alpha-white);
    height: 200px;
    border-radius: 5px;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;


}
.loader-page-cart {
  width: 200px !important;
  background: var(--alpha-white);
  height: 200px !important;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  border: 1px solid var(--alpha-desktop-bg) !important;

}

.pick-up-delivery-inputs {
  padding-left: 0.2rem;
}
.card-modal-content-location {
  width: 100%;
  height: auto !important;
  background: var(--alpha-white);
  border-radius: 5px;
}
.card-modal-content-pickup {
  width: 100%;
  height: 100%;
  background: var(--alpha-white);
  border-radius: 5px;
}
.card-modal-payment {
  width: 100%;
  height: auto;
  display: none !important;
  background: var(--alpha-white);
  border-radius: 5px;
  padding-bottom: 3rem;
  padding: 0;
}
.card-modal-content-success {
  width: 363px;
  height: 100%;
  background: var(--alpha-white);
  border-radius: 5px;
  padding: 2rem 0;
  display: flex !important;

}

.card__body__payment {
     padding: 0 !important;
}

.modal-success {
   height: 100%;
   width: 100%;
}

.card__header.modal__header-title {
  position: sticky !important;
  top: 0 !important;
  z-index: 99999999999; 
  padding: 1rem !important;
  background: white !important;
}
.card__title.choose__payment-header {
  position: sticky !important;
  top: 50px !important;
  z-index: 99999999999; 
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  padding-bottom: 0.75rem;
  height: 30px !important;
}
.card__header.modal__header-title.payment {
     height: 50px !important;
}
.card__header {
     background: var(--text-white) !important;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-bottom: 1px solid var(--alpha-blue-back) !important;
     padding-bottom: 1rem !important;
}
.card__header .checkout-header {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
    
    
}
.card__header .close-modal {
      font-family: "Circular Std",sans-serif;
      font-style: normal;
      font-weight: 400;
      color:#000000;
      font-size: 14px;
      cursor: pointer;
}
.card__header span {
    cursor: pointer;
}
.card__title {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
} 
.media-elem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
}

.select-elem {
  width: 100%;
  padding:1rem;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 5px;
  
}
.select-elem:focus, .select-elem:active {
    outline: var(--alpha-blue-back) !important;
}
.service-center-search {
  width: 100%;
  padding: .8rem !important;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
}
.img-container {
  padding: 5px; 
  width: 35px;
  height: 35px;
  background: rgba(114, 255, 201, 0.2);
  text-align: center;
  border-radius: 50%;
 
 
}
.delivery-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
 
}

.previous-addresses .previous-addresses-heading{
  font-size: 1rem;
  color: var(--alpha-blue-back);
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.previous-addresses-heading{
  margin-bottom: 0.5rem;
}
.previous-addresses .previous-address-list{
  max-height: 15rem;
  overflow-y: auto;
}
.previous-address-list::-webkit-scrollbar{
  width: 3px !important;
}
.previous-address-list::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px var(--alpha-blue-back);
}
.previous-address-list::-webkit-scrollbar-thumb{
  background-color: var(--alpha-40);
  outline: 1px solid var(--alpha-alternative-green);
  border-radius: 8px;
}
.previous-addresses .desktopdelivery-container{
  margin-right: .5rem;
}
.previous-addresses button{
  border: none;
  background-color: var(--alpha-blue-back);
  color: white;
  height: 40px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  float: right;
  gap: 0.5rem;
  font-size: 0.8rem;
}
.select-address-btn{
  border: none;
  background-color: var(--alpha-blue-back);
  color: white;
  height: 40px;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  float: right;
  gap: 0.5rem;
  font-size: 0.8rem;
}
.desktop__doordelivery_details-modal .desktop__doordelivery-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: calc(100% - 8rem);
   width: 100%;
}
.desktop__doordelivery_details-modal .desktopdelivery-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.desktop__doordelivery_details-modal .selected__delivery-method {
     display: flex;
     flex-direction: column;
     width: 100%;
     height: auto;
}
.media-img {
    z-index: 1000;
    color: var(--alpha-blue-back) !important;
    opacity: 1;
}
.img-container-disabled {
  background: rgba(218, 219, 219, 0.2); 
 
}
.media-img-disabled {
  color: rgba(129, 139, 139, 0.2) !important;
}

.delivery-title {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
  display: block;
  margin-bottom: -.5em;

}
.delivery-address {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
  display: block;
  margin-top: .5rem;

}

.service-station-address {
    font-size: 10px !important;
}

.station-number {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  color: #8E91A2;
  margin-top: .3rem !important;
}
.delivery-note {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    color: #8E91A2;
    margin-top: 1rem !important;
}
.delivery-btn {
     width: 100%;
     position: sticky !important;
     position: -webkit-sticky !important;
     top: 50% !important;
}

.delivery-title-disabled {
    color: rgb(202, 202, 202);
}

.delivery-btn button {
  
    width: 100%;
    background: var(--alpha-green);
    border-radius: 8px;
    height: 50px;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
    border: 1px solid var(--alpha-green);
}

.location-btn button {
  width: 100%;
  margin-top: 1rem;
  background: var(--alpha-green);
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
  border: 1px solid var(--alpha-green);
}

.location-btn-disabled button {
  width: 100%;
  margin-top: 2rem;
  background: rgba(142, 145, 162, 0.1);
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #8E91A2;
  border: 1px solid rgba(142, 145, 162, 0.1);
}
.delivery-btn-disabled button {
  width: 100%;
  margin-top: 2rem;
  background: rgba(142, 145, 162, 0.1);
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #8E91A2;
  border: 1px solid rgba(142, 145, 162, 0.1);
}
.delivery-details {
    width: 210px !important;
}
.delivery-checkbox {
    width: 120px !important;
}
.delivery-checkbox-style {
    width: 150px !important;
}
.address-checkbox {
  width: 180px !important;
}

.process-btn button {
  width: 100%;
  margin-top: 5rem;
  background: var(--alpha-green);
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
  border: 1px solid var(--alpha-green);
}
.process-btn.error button {
   margin-top: 0 !important;
}
.search-location-input {
    width: 100%;
    position: relative;
    padding-bottom: 3.5rem !important;
}
.search-location-input input {
    width: 100%;
    padding: .5rem;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    position: absolute;
}
.search-location-input input:focus {
    outline: none;
}
.search-location-input span .cancel-icon{
    position: absolute;
    z-index: 1000;
    top: 1rem;
    right: 1rem;
    color: #000000;
    font-size: 400;
    cursor: pointer;
    font-weight: normal;
}
.delivery-details-input {
    display: flex;
    flex-direction: column;
}
.delivery-details-input input {
  height: 34.62px;
}
.delivery-details-input .select-state {
    height: 40px;
    font-size: 12px;
}
.delivery-details-input .select-state .disabled-option{
      color: var(--alpha-80);
}
.delivery-details-input textarea {
  height: 70px;
}
.delivery-details-input textarea, .delivery-details-input input, .delivery-details-input .select-state {
    border: 1px solid rgba(238, 238, 238, 0.8);
    box-sizing: border-box;
    border-radius: 5px;
    padding: .5rem;
}

.delivery-details-input textarea:focus, .delivery-details-input input:focus, .delivery-details-input .select-state:focus {
    outline: none;
    border: 1px solid var(--alpha-blue-back);
    transition: 0.3s ease;
}
.delivery-details-input label {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--alpha-blue-back);
  opacity: 0.75;
}
.delivery-details-input .station-name {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid rgba(238, 238, 238, 0.8);
  background: rgba(238, 238, 238, 0.8);
  width: 100%;
  height: 40px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.success-modal-content {
     width: 98%;
     height: 100%;
     padding: 1rem;
     display: flex !important;
     justify-content: space-around;
     align-items: center; 
     margin: 1rem auto;
     gap: 1rem !important;
     flex-direction: column;
}
.modal-content-footer {
    width: 100%;
    height: 100%;
    padding: 1rem;
}
.success-circle {
    width: 153.39px;
    height: 153.39px;
    border-radius: 50%;
    border: 1px solid var(--alpha-success-dark);
    box-sizing: border-box;
}
.failure-circle {
  width: 153.39px;
  height: 153.39px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid var(--alpha-red);
  box-sizing: border-box;
}
.success-image {
   margin-bottom: 0.5rem;
}
.success-image .success-icon {
  width: 47px;
  height: 47px;
  color: var(--alpha-green);
}

.success-image .failure-icon {
  width: 47px;
  height: 47px;
  color: var(--alpha-red)
}

.success-text {
      width: 90%;
      font-family: "Circular Std",sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.25px;
      color: var(--alpha-blue-back);


}
.success-message {
    width: 95%;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #8E91A2;
}
.failure-message {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #8E91A2;
}
.tracking-number {
    color: var(--alpha-blue-back) !important
}
.content-footer {
    width: 100% !important;
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
}
.content-footer p {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.743307px;
    color: var(--alpha-40);
    text-align: center;
    margin-bottom: -0.2rem;
}
.download-app-links {
    width: 90%;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem auto;
}
.download-app.ios-download, .download-app.android-download {
  position: initial !important;
}
.download-app {
    width: 150px;
    cursor: pointer;
   
}
.download-app-alt {
  width: 150px;
  cursor: pointer;
}
.download-playstore {
    height: 57px;
}
.ios-download {
  cursor: pointer;
}
.android-download {
     cursor: pointer;

}
.cancel-success-modal {
    position: absolute;
    right: 3%;
    top: 0%;
    cursor: pointer;
    z-index: 10000000;
}
.cancel-success-desktop {
  position: absolute;
  right: 3%;
  top: 0%;
  cursor: pointer;
  z-index: 10000000;
}
.cancel-success-desktop .success-cancel {
     display: relative !important;
}
.danger-text {
  width: 90%;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.25px;
  color: var(--alpha-blue-back);


}

.input-text {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: var(--alpha-blue-back);
}
.payment__type {
    width: 100%;
    height: 100% !important;
    margin-top: -2rem;
    padding: .2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: space-between !important;
    justify-content: center;
}
.paystack-text, .flutterwave-text, .cod-text {
    color: var(--alpha-black);
    font-weight: 700;
    font-family: "Circular Std",sans-serif;
    margin-top: 1rem;
    font-size: 14px;
    flex: 1;
    margin-left: 0.5rem;
}
.paystack-text.desktop {
    margin-left: 0 !important;
}
.paystack__and_cod-payment {
     display: flex !important;
     padding: 1rem !important; 
}

.payment-btn button {
  width: 100%;
  background: var(--alpha-green);
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
  border: 1px solid var(--alpha-green);
}
.card__info {
     width: 100%;
     padding: 1rem 0;
}
.card__info.section {
    max-height: 250px !important;
}
.card__info .items.headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(194, 208, 104, 0.4);
    
}
.card__info .items.headers .item-key.header:nth-child(1){
    width: 60% !important;
    display: flex;
    justify-content: flex-start;
}
.card__info .items.headers .item-key.header:nth-child(2){
  width: 15% !important;
  display: flex;
  justify-content: center;
}
.card__info .items.headers .item-key.header:nth-child(3){
  width: 20% !important;
  display: flex;
  justify-content: flex-end;
}
.payment__btn__disabled button{
  width: 100%;
  background: rgba(142, 145, 162, 0.1) !important;
  border-radius: 8px;
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #8E91A2;
  border: 1px solid rgba(142, 145, 162, 0.1);
}

.payment__btn-homedelivery__disabled {
  width: 94%;
  margin: 1rem auto;
}

.payment__btn-homedelivery__disabled button{
  width: 100%;
  background: rgba(142, 145, 162, 0.1) !important;
  border-radius: 8px;
  border-radius: 8px;
  height: 50px;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #8E91A2;
  border: 1px solid rgba(142, 145, 162, 0.1);
}
.mobile-payment-style .payment-coupon, .desktop__payment_details-modal .payment-coupon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.35rem;
    font-family: "Circular Std", sans-serif;
    letter-spacing: 0.5px;
    font-size: 12px;
}
.mobile-payment-style .payment-coupon input, .desktop__payment_details-modal .payment-coupon input{
  width: 201px;
  height: 45px;
  padding: 0 0.5rem;
  font-weight: 450;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  line-height: 15.18px;
}
.mobile-payment-style .payment-coupon div, .desktop__payment_details-modal .payment-coupon div {
     color: #192150;
     font-weight: 500;
     line-height: 15.18px;
}
.mobile-payment-style .payment-coupon div {
    max-width: 100%;
}

.mobile-payment-style .payment-coupon input:focus, .desktop__payment_details-modal .payment-coupon input:focus{
   outline: var(--alpha-blue-back) !important;
   border-color: var(--alpha-blue-back) !important;
}
.mobile-payment-style .payment-coupon button, .desktop__payment_details-modal .payment-coupon button{
    width: 90px;
    height: 45px;
    border-radius: 8px;
    background: var(--alpha-green);
    color: var(--alpha-blue-back);
    border: none;
    font-family: "Circular Std", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15.18px;
    letter-spacing: 0.5px;
}
.desktop__payment_details-modal .payment-coupon .delivery-verified-success, .mobile-payment-style .payment-coupon .delivery-verified-success {
    margin-top: -0.5rem;
}
.mobile-payment-style .payment-coupon .coupon-header {
     margin-bottom: -0.05rem;
}
.mobile-payment-style .payment-coupon .coupon__header-success {
    margin-bottom: -0.5rem !important;
}
.desktop__payment_details-modal .payment-coupon .coupon__header-success{
   margin-bottom: -1rem !important;
}
.card_details_placeholder {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
}
.payment-method-container {
   width: 40%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1rem;
}
.payment-method-logo {
    width: 34.11px;
    height: 11.99px;
}

.hr-color {
    border: .2px solid rgba(194, 208, 104, 0.4) !important;
    background-color: rgba(194, 208, 104, 0.4) !important;
}
.enter-card-details {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
    margin-left: .2rem;
}
.details-card {
     height: 45px;
     width: 100%;
     padding: .5rem;
     border: 1px solid #EEEEEE;
     box-sizing: border-box;
     border-radius: 5px;
     margin-bottom: 1rem;
     margin-top: .3rem;
}
.details-card:focus {
    outline: none;
} 
.payment-hr {
     width: 100% !important;
     margin-top: -0.5rem;
}

.items {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.total-cash {
     display: flex;
     flex-direction: column;
     justify-content: center;
     padding: 0px 1rem;
}
.total-cash .delivery {
    display: flex;
    justify-content: space-between;
}
.card__info .product__info_container {
    height: 150px;
    overflow-y: auto;
}
.card__info .product__info_container.mobile__container-info {
    height: auto !important;
}
.card__info .product__info-section   {
    display: flex;
    flex-direction: column;
     justify-content: space-between;

}
.card__info .product__info-section:nth-child(1){
     margin-top: 1rem;
}
.card__info .product__info-section:nth-child(n+2){
    margin-top: -0.5rem;
}
.card__info .item-key.product__name_and-eta {
    display: flex;
    flex-direction: column;
}
.card__info .product__info-section .item-key:nth-child(1) {
     width: 60%;
     display: flex;
     justify-content: flex-start;
}
.card__info .product__info-section .item-key:nth-child(2) {
  width: 15%;
  display: flex;
  justify-content: center;
}
.card__info .product__info-section .item-key:nth-child(3) {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}
.item-key {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #8E91A2;
}
.item-key.header {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);  
}
.card__info .eta-section {
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    margin-top: -0.75rem !important;
    color: var(--alpha-alternative-green);
}
.item-value {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
}
.delivery-fee-values {
    display: flex;
    align-items: center;
    
}
.main-delivery-price {
     margin-right: 1em;
     text-decoration: line-through;
}
.total-value {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}
.total-key {
  font-weight: 500;
  color: var(--alpha-blue-back);
}
.fetch-shipment {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100%;
     gap: 5px;
}
.shipment-spinner {
    width: 2rem !important;
    height: 2rem !important;
    color: var(--alpha-blue-back) !important;
 
}
.shipment-spinner-desktop-sc {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.fetch-shipment span {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
     color: var(--alpha-blue-back);
}
.loader-style {
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-spinner {
      width: 6rem !important;
      height: 6rem !important;
      margin-left: auto;
      margin-right: auto;
      color: var(--alpha-blue-back) !important;
}
.spinner-size {
  display: flex !important;
  justify-content: center !important;
  width: 4rem !important;
  height: 4rem !important;
  margin-left: auto;
  margin-right: auto;
}
.delivery-spinner {
  display: flex !important;
  justify-content: center !important;
  width: 6rem !important;
  height: 6rem !important;
  margin-left: auto;
  margin-right: auto;
  color: var(--alpha-green) !important;
}
.color-picker  {
    width: 100% !important;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    align-items: center;
    text-align: center !important;
    gap: 0.5rem;
}

.color-picker::-webkit-scrollbar { 
  display: none;
}
.color-picker button {
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer !important;
  outline: none;
  border: 1px solid var(--alpha-body-color) !important;
  text-align: center;
  transform: scale(1);
  transition: all .5s ease-in-out;
}
.loader-style-eta {
   width: 100%;
   height: auto;
   margin-top: 1rem;
   display: flex;
   align-items: center;
   gap: 6px;
   font-family: "Inter",sans-serif;
   font-size: 0.8rem;
   font-weight: normal;
   
}
.loader-spinner-eta {
  width: 1.5rem !important;
  height: 1.5rem !important;
  color: var(--alpha-green) !important;
}
.eta-display {
      font-family: "Inter",sans-serif;
      margin-top: 1rem;
      border: 1px solid var(--alpha-green);
      background: rgba(114, 255, 201, 0.2);
      border-radius: 5px;
      font-size: 12px;
      padding: 1rem;
      color: var(--alpha-alternative-green);
      text-align: center;
}

.color-picker button:hover {
  transform: scale(0.95);
  border: 2px solid var(--alpha-blue-back) !important;
    
}

.color-check-white-bg {
    font-size: 14px;
    color: #000000;
    text-align: center !important;
    width: 100%;
    transition: all .3s ease-in-out;
}

.color-check-other-bg {
  font-size: 14px;
  color: #ffffff;
  text-align: center !important;
  width: 100%;
  transition: all .3s ease-in-out;
}
.color-picker .border-white-bg {
    border: 2px solid var(--alpha-black) !important;
}
.color-picker .border-other-bg {
     border: 2px solid var(--alpha-green) !important;
}
.hide-active-color {
   display: none !important;
   transition: all .3s ease-in-out;

}
.show-active-color {
     display: block !important;
     transition: all .3s ease-in-out;
}
.review-list-header {
    display: flex;
    align-items: center !important;
}

.review-count {
     margin-left: 5rem;
     margin-top: -.5rem;
     background: var(--alpha-10);
     border-radius: 50%;
     width: 22px;
     height: 22px;
     text-align: center;
     font-family: "Inter",sans-serif;
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
}

.reviewer-name {
   display: flex;
   flex-direction: column;
}
.reviewer-name .name {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;  
    letter-spacing: 0.5px;
    color: #1C1C1C;  
    margin-left: 0;
}
.reviewer-name .date {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: -0.743307px;
    color: #807D7D;
}
.review-details {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;    
    text-align: justify;
    color: var(--alpha-80);
    margin-left: 3rem;
}
.thumbs-icon {
    color: rgba(21, 44, 67, 0.5);
}
.user-review-image {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     border: 1px solid black;
     text-align: center !important;
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
     background: var(--alpha-blue-back) !important;
     color: var(--alpha-white) !important;
}
.prod-description {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: var(--alpha-80);
}
.review-notfound {
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  color: var(--alpha-blue-back);
}

.gift-wrap-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.gift-wrap-label {
   width: 85%;
   display: flex;
   flex-direction: column;
}
.gift-wrap-label span:first-child {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;  
  text-align: justify;  
  color: var(--alpha-blue-back)
}
.gift-wrap-label span:last-child {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: var(--alpha-80);
}
.gift-wrap-input {
    width: 15%;
    margin-right: -1.5rem;
}
.app-download {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 
.download-app-icons {
    display: flex;
    align-items: center;
    margin-top: -3rem;
}

.app-download span {
  font-family: "Circular Std",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;  
  letter-spacing: -0.743307px;
  color: var(--alpha-40);
}
.download-playstore {
  margin-left: 1rem;
}
.error_detail_page {
    display: flex;
    height: 200px;
    width: 100%;
    align-items: center;
}
.media-elem-pickup {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}
.delivery-details-pickup {
  width: 80%;
}
.address-checkbox-pickup {
  width: 1em;
  height: 1em;
  accent-color: var(--alpha-blue-back) !important;
}
.star > .star-rating {
    color: var(--alpha-yellow);
}

.price-rating-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .5rem auto;
}
.price-rating-section .all-ratings {
     color: var(--alpha-yellow);
     margin-top: 0;
}
.ratings-space {
  display: flex !important;
  gap: 10px;
  align-items: center !important;
  margin-top: -1rem;
}
.ratings-space .total-reviews {
    margin-top: 0.6rem;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.75px;
    color: #192150;
    }
    .display-review-mb {
       padding: 0 1rem;
    }

    .ratings-space-desktop {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .ratings-space-desktop .total-reviews-desktop {
      font-family: "Circular Std",sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.75px;      
      color: var(--alpha-blue-back)
    }
    .player-n-payment {
        display: flex;
        height: auto;
        margin: 0.5rem 0;
        width: 100%;
        gap: 0.5rem;
        align-items: center;
    }
    .video-play-btn {
        font-size: 2rem;
        height: auto;
        cursor: pointer;
        color: var(--alpha-blue-back);
        border: 1px solid var(--alpha-alternative-green);
        border-radius: 50%;
    }
    .video-play-btn.player_bg {
        color: var(--text-white) !important;
        border: none !important;
    }
    .watch-prod-video span {
        font-family: "Inter",sans-serif;
        font-size: 0.8rem !important;
        
    }
  .watch-prod-video {
      background: transparent;
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      font-family: "Circular Std", sans-serif;
      width: 48px;
      transition: background-color 0.3s ease;
      padding: 0.2rem 0.4rem !important;
      color: var(--alpha-blue-back) !important;
  }
.watch-prod-video.show_text {
      background-color: var(--alpha-green) !important;
      border-radius: 5px !important;
      color: var(--alpha-blue-back) !important;
 }
.color__variation {
    /* min-height: 100px; */
    width: 100%;
    margin-top: 1rem;
    background: #F7F7F7;
    border-radius: 6px;
}
.color__variation .item-model, .color__variation .item-color, .color__variation .item-location {
     min-height: 35.31px;
     width: 100%;
     display: flex;
     align-items: center;
     padding: 0.5rem;


}
.color__variation .item-model .item, .color__variation .item-color .item, .color__variation .item-location .item {
     display: flex;
     gap: 20px;
}

.color__variation .item-color {
    background: #E5E5E599;
}
.color__variation .variation-label{
    width: 127px !important;
    font-family: "Inter",sans-serif;
    line-height: 20.44px;
    letter-spacing: -0.19px;
    font-size: 13.94px;
    font-weight: 400;
    color: #545454;
}
.color__variation div:nth-child(2){
     font-family: "Inter",sans-serif;
     font-weight: 600;
     font-size: 13.94px;
     line-height: 20.44px;
     letter-spacing: -0.09px;
     color: #1C1C1C;
}

 .mobile-player-sec {
     width: 100%;
     line-height: 16px;
     margin-bottom: 10px;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }
 .mobile-player-sec .item-video-mobile {
       display: flex;
       line-height: 16px;
       cursor: pointer;
       font-family: "Circular Std",sans-serif;
       font-weight: bold;
       gap: 0.3rem;
       align-items: center;
       background: var(--alpha-green);
       padding: 0.5rem;
       color: var(--alpha-blue-back);
       border-radius: 12px;
 }
 .item-video-mobile span {
     font-family: "Circular Std",sans-serif;
     font-size: 12px;
 }
 .mobile-player-sec .item-video-mobile .video-icon {
     font-size: 1.2rem;
 }
 .share-product-desktop {
     color: #545454;
     background: transparent;
     display: flex;
     align-items: center;
     align-items: center;
     justify-content: center;
     gap: 0.5rem;
     height: 40px;
     width: 178px;
     border-radius: 10px;
     border: 1px solid #33333366;
     cursor: pointer;
     z-index: 2;
 }
.paystack__radio-cod {
    accent-color: var(--alpha-blue-back);
}
@media screen and (max-width: 768px){
  body, html {
    -ms-overflow-style: none;
  }
  body::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

}

@media screen and (min-width: 48em){

  .product__detail-container .back-btn {
      font-size: 16px;
  }
   .product-img-desktop-slide {
       display: flex;
       flex-direction: column;
   }
   .desktop__slider-section {
       display: flex;
       flex-direction: row;
       width: 100%;
       gap: 1rem;
   }

   .desktop__item-price {
        display: flex;
        align-items: center;
        gap: 1rem;
   }

   .desktop__item-price .price{
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      gap: 1rem;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      font-weight: 800;
      line-height: 32px;
      color: #333333;
   }
  .desktop__item-price .price.desktop-discounted-price {
      font-size: 16px !important;
      color: var(--alpha-40);
      text-decoration: line-through;
      text-decoration-color: var(--alpha-red);
  }
  .location-address-section div {
      font-size: 0.8em;
  }
  .location-address-section {
     flex: 1;
  }
  .desktop-prod-details-col {
       height: auto;
  }
  .showcase {
    min-height: calc(100vh - 500px);
  }
  .card-modal-payment {
    display: block !important;
  }
  .modals-container-pickup, .desktop__checkout-modal.card, .desktop__checkout_pickup-modal.card, .desktop__doordelivery_details-modal.card,.desktop__payment_details-modal.card,.desktop__success-modal.card {
       width: 55% !important;
       max-width: 570px !important;
  }
  .show_more-btn{
    width: 50%;
    outline: none !important;
  }
  .qty-btn-section .add-remove-qty {
      width: 75%;
   }
 
.navigation-bar.scrolled {
     background: var(--alpha-desktop-bg) !important;
}
.user-auth {
     display: flex;
}
  #auth_popup {
    right: 4.5rem;
    width: 229px;
}
  .modals-container {
     display: block;
     width: 50% !important;
     top: 30% !important;
  }
  .overlay {
     display: block;
  }
  .modal-success {
    height: auto;
  }
 
  .success-modal {
       width: 45%;
       height: 80% !important;
       display: flex !important;
       justify-content: center !important;
       align-items: center !important;
  }
  .door-delivery-modal {
      top: 40% !important;
  }
  .card-image {
    max-height: 22rem;
    min-height: 22rem;
    height: 100%;
    box-shadow: unset;
    background: transparent !important;
    border-radius: 0 !important;
  }
  
  .card-image img, .card-image .video-react {
       width: 100%;
       height: 100%;
       border-radius: 5px;
       object-fit: cover !important;
  }
  .image-thumbnails {
    width: 65px !important;
    height: 65px !important;
  }
  .active {
    border: 2px solid #849614 !important;

}
  .product-name {
    font-size: 24px !important;
    font-family: "Inter", sans-serif;
    line-height: 38px;
     max-width: 464px;
    word-wrap: break-word;
    margin-top: initial;
  }
  .navigation-items {
    width: 95% !important;
    margin: auto;
  }
  .navigation-items .logo img {
    width: 70px;
    display: initial;
    margin: 1rem auto;
  
  }
 
.navigation-items .search {
    display: flex !important;
    align-items: center !important;
    width: 50% !important;
    margin-right: 3rem;
    justify-content: space-between !important;
}
.navigation-items .logo {
    width: 20% !important;
    height: 100% !important;
}
.search input {
    width: 100%;
    padding: .5rem 1rem .5rem 1.7rem;
    background: #FFFFFF;
    opacity: 0.75;
    border: 1px solid var(--alpha-40);
    border-radius: 8px;
    position: absolute;
}
.search .input-search-section {
     width: 100%;
     display: flex;
     align-items: center;
} 
.search .select-categories-toggle {
  position: absolute;
  right: 2%;
  cursor: pointer;
  z-index: 100000;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  letter-spacing: -0.02em;
   color: var(--alpha-80);
   display: flex;
   align-items: center;
   height: 100%;
   gap: 1rem
}
.search .select-categories-toggle > span:nth-child(2){
   margin-top: -0.2rem;
   font-size: 15px;
}
.search-input .search-icon {
  z-index: 10000;
  color: var(--alpha-40) !important;
}

.search-input .filter-icon {
  position: absolute;
  right: -1.2rem;
  top: .8rem;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
   color: var(--alpha-40);
}
.default-modal {
  top: 55% !important;
}
.delivery-modals {
    top: 40% !important;
}

.modals-container-payment {
   top: 40% !important; 
}

.qty-price .price {
  font-weight: 800;
  font-size: 20px;
}
.add-qty {
  font-weight: 600;
  font-size: 18px;
}
.btn-minus, .btn-plus, .btn-qty {
  padding: .5rem 1.2rem;
}

.mobile-display {
     display: none;
}
.product-card {
     padding: 1rem 0 !important;
     height: auto;

}
.desktop-display {
   display: block;
   margin: 1rem auto;
}
.product-img-desktop-slide {
     display: flex;
     gap: 1rem;
     align-items: flex-start;
}
.review-section {
    display: block;
}

.footer-container {
    display: flex;
    width: 95% !important;
    margin: auto;
    justify-content: space-between;
    align-items: center;
}
.scroll-to-top .social-icons {
  margin: .5em auto;

}
.footer-policy{
  text-align: left;

}
.social-icons-desktop {
     display: flex;
     gap: 0.5rem;
}
.social-icons-desktop .icon-social a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 50%;
  color: var(--alpha-black);
  background: var(--alpha-bg-color);
}
.footer-bar {
    padding: 3rem 0 5rem 0;
    width: 100% !important;
    margin-top: initial;

   
}
.scroll-to-top {
    display: none;
}
.footer-hr {
  display: block;
  width: 95% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.footer-snippet {
    color: #969696;
    padding-top: initial;
}
.delivery_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 0;
} 
.icon_container {
  background: #72FFC933;
  text-align: center;
  padding: .4em .7em;
  margin-right: 1rem;
  border-radius: 50%;
}
.pickup-delivery-container {
     width: 100% !important;
}
.delivery_icon_container {
  text-align: center;
  padding: .4em .7em;
  margin-right: 1rem;
  border-radius: 50%;
}
.radio__icon {
  color: var(--alpha-blue-back) !important;
}
.radio-b, .radio-c, .radio__pickup   {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100% !important;
}
.delivery-checkbox-pickup {
    display: none;
}
.radio__pickup {
  justify-content: space-between;
  width: 100%;
}
input.paystack__radio-cod {
     width: 1.1rem;
     height: 1.1rem;
     accent-color: var(--alpha-blue-back);
     border: 2px solid var(--alpha-blue-back)
}
.radio_pickup_btn_btn {
    width: 1em;
    height: 1em;
    border: 2px solid var(--alpha-blue-back);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2px;

}
.radio_pickup_btn_btn::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: var(--alpha-blue-back);
  border-radius: 50%;
}

.delivery-checkbox-pickup:checked + .radio_pickup_btn_btn::after {
  transform: scale(1);
}


.radio__btn {
  width: 1.2em;
  height: 1.2em;
  border: 2px solid var(--alpha-black);
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;
  margin-left: 1rem;
}
.radio__btn::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: var(--alpha-blue-back);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.15s;
}
.radio_input:checked + .radio__btn::after {
  transform: scale(1);
}

.delivery__radio_btn {
  width: 1em;
  height: 1em;
  border: 2px solid var(--alpha-black);
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;
  margin-left: 3rem;
}
.delivery__radio_btn::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: var(--alpha-blue-back);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.15s;
}
.delivery_radio:checked + .delivery__radio_btn::after {
  transform: scale(1);
}

.delivery_details_holder, .delivery-details {
     width: 90% !important;
}
.delivery_radio {
   display: none;
}

.delivery_radio_disabled {
  margin-left: 3rem;
  width: 1rem;
  height: 1rem;
}
.radio_input {
  display: none;
  
}
.paystack__radio {
    display: none;
}
.payment_section {
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  height: 100%;
  align-items: center !important;
}

.payment_section img {
    width: 30px;
    height: 30px;
    
}
.paystack_input_desktop {
  height: 1.2em;
  width: 1.2em;
  accent-color: var(--alpha-blue-back);
}
.paystack_input_desktop_home {
    display: none;
}
.radio__paystack {
    height: 1.2em;
    width: 1.2em;
    border: 2px solid var(--alpha-blue-back);
    border-radius: 50% !important;
    padding: 2px;
}
.radio__paystack::after {
    content: "";
    width: 100%;
    height: 100%;
    background: var(--alpha-blue-back);
    display: block;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
}
.paystack_input_desktop_home:checked + .radio__paystack::after {
  transform: scale(1);
}
.paystack_radio_btn {
  width: 1em;
  height: 1em;
  border: 2px solid var(--alpha-blue-back);
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;

}
.delivery_modal_details {
     width: 100%;
}

.delivery_modal_details .delivery_modal_title, .delivery_details_holder .delivery_name {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: var(--alpha-blue-back);
}

.delivery_modal_details .delivery_modal_note, .delivery_details_holder .delivery_address, .delivery_details_holder .delivery_email {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;  
    letter-spacing: 0.5px; 
    color: #8E91A2;
}
.delivery_title_name {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
}
.delivery_title_address {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;  
  letter-spacing: 0.5px; 
}
.delivery_fullName{
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;  
  letter-spacing: 0.5px; 
}
.mobile-app-download {
  width: 100%;
  height: 270px;
  padding: 10px;
  display: flex;
  margin: 0 auto 1rem auto;
}

.app-download-container {
  display: flex;
  width: 100%;
  padding: 0 1rem 0 0;
}
.app-icons-show {
   flex: 33%;
}
.gig-alpha-download-desktop {
     display: flex;
     flex: 66%;
     justify-content: space-between;
     align-items: center;
}
.download-icons-desktop {
     display: flex;
     flex: 40%;
     margin-left: 4em;
     align-items: center;
}
.alpha-vertical-divider {
    width: 1px;
    height: 50%;
    background: white;
    margin-left: 4rem;
}
.gig-alpha-download-desktop .try-alpha {
    flex: 33%;
}
.gig-alpha-download-desktop .hide-on-desktop {
     display: none;
}
.download-icons-desktop a img {
  width: 10em;
  min-width: 110px;
  min-height: 60px;
  height: 60px;
}
 .app-icons-show .pixel-two {
  height: 85%;
  width: 130px;
  top: 2.8em;
}
.app-icons-show .pixel-one {
  height: 92%;
  width: 130px;
  top: 1.76em
}

.app-icons-show .pixel-two  {
    margin-left: 4rem;
}

.gig-alpha-download{
  display: none;
} 

.gig-alpha-download-desktop h4 {
    
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: var(--alpha-white);
    width: 100%;
}
.gig-alpha-download-desktop p {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--alpha-white);
}
.gig-alpha-download div {
  position: initial;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  color: var(--alpha-white);

}

.download-icons-mobile {
    width: 100%;
    display: inline-flex;
   margin-right: 1.5rem;
}

.mobile-download-appstore {
  width: 80px;
  height: 100%; 
  margin-left: 5px;
}
.mobile-download-playstore {
      width: 80px;
      height: 100% !important;
    
}
.store-name-placeholder .store-img-n-name {
    display: flex !important;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  
}
.store-name-placeholder .store-img-n-name div {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  border: 2px solid var(--alpha-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
.store-img-n-name div img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: contain;
    background: #f9f9f9 !important;
}
.qty-btn-section {
  width: 100%;
  display: flex;
  align-items: center;
}
.desktop-price-section {
  display: flex;
  align-items: center;
}
.color-picker button {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
}
.qty-btn-section .add-qty {
  width: 25%;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.75px;
  color: #757575;
}
.desktop__payment_details-modal .payment-coupon div {
  max-width: 80%;
}

}


@media screen and (min-width:48em){
  .card-image {
    max-height: 27rem;
    min-height: 22rem;
    height: 100% !important;
    background: #f9f9f9 !important;
  }
   .card-image .video-react {
      object-fit: contain !important;
  }
  .card-image img {
     object-fit: cover !important;
  }
}
@media screen and (min-width: 50em) {
    .search-input .filter-icon {
      right: 0rem;
    }
  .modals-container {
      top: 35% !important;
  }

}

@media screen and (min-width:51.875em){
  .search-input .filter-icon {
    right: 1rem;
  }
}

@media screen and (min-width: 53.875em) {
  .search-input .filter-icon {
    right: 2rem;
  }
}

@media screen and (min-width: 55.625em) {
  .search-input .filter-icon {
    right: 3.5rem;
  }
}
@media screen and (min-width: 57em){
  .search-input .filter-icon {
    right: 5rem;
  }
  .qty-price .price {
    font-size: 24px;
  }
  .delivery-container, .modals-container {
      top: 30% !important;
  }
  .modals-container-payment {
      top: 50% !important;
  }
  .product-name {
    width: 100% !important;
  }
}

@media screen and (min-width: 62.5em) {
  .card-image img, .card-image .video-react {
    object-fit: cover !important;
}
  .modals-container-pickup, .desktop__checkout-modal.card, .desktop__checkout_pickup-modal.card, .desktop__doordelivery_details-modal.card,.desktop__payment_details-modal.card {
      width: 45%;
      max-width: 570px !important;
  }
  .desktop__success-modal.card {
       width: 40% !important;
  }
  .search-input .filter-icon {
    right: 7.5rem;
  }
  .modals-container {
      top: 50% !important;      
  }
  .delivery-container {
    top: 50% !important;
  }
  .door-delivery-modal {
    top: 55% !important;
}
.success-modal {
  height: 90% !important;
}
.desktop__payment_details-modal .payment-coupon div {
  max-width: 60%;
}
}

@media screen and (min-width: 64em){
  #auth_popup {
    position: absolute;
    right: 9rem;
}

  .navigation-items .logo {
      width: 20% !important;
  }
  .search-input input {
    margin-top: 0;

  }
  .search-input .filter-icon {
    right: 3rem;
  }
  .modals-container {
    width: 45% !important;
   }

}

@media screen and (min-width: 67.5em){ 
  .search-input .filter-icon {
    right: 4.5rem;
  }
  .navigation-items {
    max-width: 1080px !important;
    padding: unset;
  }
}

@media screen and (min-width: 70em){ 
  .search-input .filter-icon {
    right: 6.5rem;
  }
}
@media screen and (min-width:73.5em){
  .search-input .filter-icon {
    right: 8rem;
  }
}

@media screen and (min-width: 75em){
  .search-input .filter-icon {
    right: 9.5rem;
  }
}

@media screen and (min-width: 78.125em){
  .card-image {
      min-height: 27em !important;
      box-shadow: none !important;
  }
 .card-image .video-react {
    object-fit: contain !important;
   
  }
  .search-input .filter-icon {
    right: 10.5rem;
  }
  .footer-hr, .footer-container, .terms-condition-container {
      max-width: 1080px !important;
  }
}

@media screen and (min-width:  79.375em){
  .search-input .filter-icon {
    right: 12rem;
  }
  .product-card-details, .related__product_view-more {
       width: 1080px !important;
   }
   .product__detail-container {
      max-width: 1080px;
   }

.product__detail-container .back-btn {
     font-size: 18px;
}
 
}

@media screen and (min-width: 82.5em){
  .search-input .filter-icon {
    right: 14rem;
  }
}

@media screen and (min-width: 85.625em){
  .search-input .filter-icon {
    right: 17rem;
  }
}

@media screen and (min-width: 90.625em){
  .search-input .filter-icon {
    right: 19.5rem;
  }
}

@media screen and (min-width: 92.625em){
  .search-input .filter-icon {
    right: 22.5rem;
  }
}

@media screen and (min-width: 100em) {
  .search-input .filter-icon {
    right: 24.5rem;
  }
}

@media screen and (min-width: 103.125em) {
  .search-input .filter-icon {
    right: 27rem;
  }
}

@media screen and (min-width: 107.5em) {
  .search-input .filter-icon {
    right: 29rem;
  }
}

@media screen and (min-width: 110.625em) {
  .search-input .filter-icon {
    right: 31rem;
  }
}

@media screen and (min-width: 113.75em) {
  .search-input .filter-icon {
    right: 33rem;
  }
}

@media screen and (min-width: 116.875em) {
  .search-input .filter-icon {
    right: 35.5rem;
  }
}