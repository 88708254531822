.rating-n-name {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
}
.display-review-mb:first-child {
    margin-top: 0.5rem;
}
.display-review-mb:nth-child(n+2){
    margin-top: -1rem;
}
.rating-date-n-name {
    display: flex;
    flex-direction: column;
}
.rating-date-n-name .rate-name {
    font-family: 'Circular Std',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
}
.rating-date-n-name .rated-on {
    font-family: 'Circular Std',sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 8px;
    line-height: 14px;
    letter-spacing: -0.743307px;
    color: #807D7D;
}
.display-review-mb .review-msg, .no-reviews-yet {
    font-family: 'Circular Std',sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;  
    color: var(--alpha-80)
}
