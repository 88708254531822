.main-item {
    padding: 10px;
    background-color: #fff;
    width: 90%;
    margin: auto;
  }
  
  .background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  
  .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  
  .static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .shared-dom {
    width: 100%;
    height: 110px;
  }
  .sub-rect {
    border-radius: 100%;
    width: 3em;
    height: 3em;
    float: left;
    margin: 20px 20px 20px 0;
  }
  .pure-background {
    background-color: #eee;
  }
  .sub-rect:nth-child(5), .sub-rect:nth-child(6), .sub-rect:nth-child(7){
        display: none;
  }
  @media only screen and (min-width:48em){
    .sub-rect {
        width: 4.375em;
        height: 4.375em;
      }
      .sub-rect:nth-child(5), .sub-rect:nth-child(6), .sub-rect:nth-child(7){
        display: block;
  }
  }