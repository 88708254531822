.store-container {
     width: 100%;
     height: 100%;
}
.store-content {
    width: 95%;
    margin: auto;
    height: 100%;
    padding: 2rem 0;
}
.upload-section  {
    width: 95%;
    margin: auto;
    height: 100%;
    padding: 2rem 0;
}
.store-header {
    width: 100%;
    height: 191px;
    max-height: 280px;
    background: var(--alpha-blue-back);

}
.header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    height: 100%;
    width: 95%;
    margin: auto;
}
.header-content .store-title {
      max-width: 476px;
      font-family: "Circular Std", sans-serif;
}
.header-content .store-title h1 {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
}
.header-content .store-title p {
    color: var(--alpha-white);
    font-size: 16px;
    font-weight: 450;
   
}
.header-content img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 2px solid var(--alpha-green);
}

.action-nav {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      width: 100%;
      font-weight: 500;
}

.action-nav, .store-category .all-category {
    -ms-overflow-style: none;
    scrollbar-width: none;  /* Firefox */
}
.action-nav::-webkit-scrollbar, .store-category .all-category::-webkit-scrollbar { 
    display: none;
}
.all-category .category-name {
     padding: 0.5rem 1rem;
     background: #F7F7F7;
     border-radius: 40px;
     font-size: 12px;
}

.action-nav a {
     text-decoration: none;
}
.action-nav .action {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 0.25rem;
     width: 150px;
     padding: 0.5rem;
     height: 40px;
     font-family: "Inter", sans-serif;
     font-size: 12px;
     border-radius: 40px;
     background: #F7F7F7;
     color: #52587C;
}

.action-nav .action img {
      width: 14px;
      height: 14px;  
}
.action-nav .upload-action {
    color: var(--alpha-blue-back);
}

.action-nav .boost-action {
      color: #ED4B9E;
}
.action-nav .copy-action {
    cursor: pointer;
}

.store-category {
     width: 100%;
     height: auto;
     display: flex;
     gap: 1.5rem;
}


.store-category .list-category {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem 0;
    color: var(--alpha-80);
    font-size: 16px;
    font-family: "Circular Std", sans-serif;
    font-weight: 450;

}
.store-category .all-category {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    overflow-x: auto;
}
.list-category .cat-text {
    font-weight: 450;
    font-size: 18px !important;
    font-family: "Circular Std", sans-serif;
    color: var(--alpha-blue-back);
}

.store-card {
    max-width: 1080px;
    margin: 0 auto;
    display: grid;
    gap: 1.5rem;
    place-items: center;
} 

.store-card .card-details {
    height: 287px;
    width: 280px;
    filter: drop-shadow(0, 0, 4px, rgba(0, 0, 0, 0.2));
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);

}

.store-card .card-details .price-description {
    font-family: "Circular Std", sans-serif;
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.store-card .card-details img {
    width: 100%; 
    height: 197px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: var(--alpha-10);
    object-fit: cover;
}
.store-card .card-details .price-description div {
    width: 90%;
    font-weight: 450;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4D4D4D;
    font-size: 14px;
    color: #000000;
}
.store-card .card-details .price-description h3 {
    font-weight: 500;
    font-size: 18px;
}
.store__show-more.storecard {
    width: 90%;
}
.nomore__show-more.storecard {
    width: 90%;
}
.alpha-red.active-category {
    color: var(--alpha-red);
}
.upload-btn {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     font-size: 14px;
     position: fixed;
     right: 1.5rem;
     top: 85vh;
     background: var(--alpha-blue-back);
     width: 70px;
     height: 70px;
     border-radius: 50%;
     box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
     z-index: 10000000;
     color: white;
     text-decoration: none;
     animation: pulse 2s infinite;
}

.store-not-exist{
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 600;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
@media (min-width: 600px) {
    .store-card { 
        grid-template-columns: repeat(2, 1fr);
    }
    .nomore__show-more.storecard {
        width: 250px !important;
    }
}

@media (min-width: 768px) {
    .store-card { 
        grid-template-columns: repeat(3, minmax(min(200px, 100%), 1fr));
        gap: 1rem;
    }
    .store-card .card-details  {
         width: 100%;
    }
}
@media (min-width: 1024px) {
    .store-card { 
        grid-template-columns: repeat(4, minmax(min(200px, 100%), 1fr)); 
       
    }
    
}

@media only screen and (max-width: 480px){
    .action-nav {
         overflow-x: auto;
         width: 100%;
         white-space: nowrap;
    }
}
@media only screen and (min-width: 48em){
    .header-content {
        flex-direction: unset;
        justify-content: unset;
        align-items: center;
        text-align: unset;
        gap: 1.5rem;
    }
    .header-content img {
        height: 90px;
        width: 90px;
    }
    .header-content .store-title h1 {
         font-size: 36px;
    }
    
}
@media only screen and (min-width: 64em){
    .header-content img {
        width: 112px;
        height: 112px;
    }
}
@media only screen and (min-width: 80em){
    .store-content, .header-content, .upload-section {
         width: 1080px !important;
    }
    
}