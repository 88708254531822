.share-options {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    opacity: 0;
    transition: opacity 0.7s linear;
}
.share-options.share-modal-show {
    display: block;
    opacity: 1;
}
.share-options .share-option-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.9);
    cursor: pointer;
}
.share-options .share-option-modal {
     position: fixed;
     height: auto;
     width: 95%;
     z-index: 99999999;
     left: 50%;
     top: 50%;
     transform: translate(-50%,-50%);
     background: var(--alpha-white);
     border-radius: 8px;
}
.share-modal-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
}
.share-modal-page .share-video-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--alpha-40);
    font-size: 1rem;
    font-family: "Circular Std", sans-serif;
    padding: 0.5rem 1rem;
    color: var(--alpha-80) !important;
    
}

.share-option-modal .share-header{
     text-align: center;
     border-bottom: 1px solid var(--alpha-40);
     width: 100%;
     padding: 0.5rem 0;
     color: var(--alpha-blue-back);
     font-family: "Inter", sans-serif;
}
.social-media-share {
    padding: 1em;
}
@media only screen and (min-width: 48em) {
    .share-options .share-option-modal {
          width: 50% !important;
    }
}