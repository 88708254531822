.prod-desc-card {
    width: 100%;
    height: 100%;
    min-height: 2rem;
}

.review-header-section {
     padding: 0.5rem 0;
     border-bottom: 1px solid var(--alpha-10);
}
.review-header-section .review-header-desc {
    font-family: "Inter", sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.review-header-section .review-header-desc div:first-child {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.74px;
    color: #1C1C1C;
}
.review-header-section .review-header-desc div:last-child {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.74px;
    color: #333333;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background: var(--alpha-10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.prod-desc-section {
    padding: 1rem 1rem 2rem 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    
}
.prod-desc-section > .prod-desc-text {
    font-family: 'Circular Std', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: var(--alpha-80);
}
.review-notfound-desktop {
    font-family: 'Circular Std', sans-serif;
    padding: 1rem;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: var(--alpha-80);
}
.app-download .download-label {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: -0.743307px;
    color: rgba(51, 51, 51, 0.4);
}
.rated-n-review {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
.rated-n-review .reviewer-name-desktop {
       display: flex;
       align-items: center;
       gap: 0.5rem;
}
.rated-n-review .reviewer-name-desktop .initials {
     min-height: 40px;
     min-width: 40px;
     background: var(--alpha-blue-back);
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     color: var(--alpha-white);
}
.reviewer__name-section {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.rated-n-review > .reviewer-name-desktop .name {
    font-family: "Inter",sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;    
    letter-spacing: -0.74px;
    color: #1C1C1C;
}
.rated-n-review > .reviewer-name-desktop .date {
    font-family: "Inter",sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;    
    letter-spacing: -0.74px;
    color: #1C1C1C;
}

.desktop-star-ratings .review-details-desktop {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #1C1C1C;
}
.rated-n-review .ratings__n_stars-section {
    padding: 0px 0.5rem 0px calc(40px + 0.5rem);
    display: flex;
    margin-top: -0.5rem;
    flex-direction: column;
    gap: 5px;
} 
.review-pagination {
    margin-left: calc(40px + 0.5rem);
}
.pagination-btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.743307px;
    color: #333333;
}
.pagination-btn button {
    height: 30px;
    width: 30px;
    background: var(--alpha-10);
    border-radius: 50%;
    border: none;
    outline: none;
}
.disabled-page .icon-caret{
     color: var(--alpha-40) !important;
}
.active-page-pagination {
    background: var(--alpha-80) !important;
    color: #FFFFFF;
}
@media screen and (min-width: 48rem) {
   .desktop-star-ratings {
        margin: 1.5rem 0;
   }
}
