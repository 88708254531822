.error-message {
     min-height: 60vh;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     
}
.error-message .error-header {
     font-weight: bold;
}
.error-message p {
     font-size: 1.2rem;
}

.error-message .back-home-btn {
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
      transition: all 0.5s ease-in;
      background: var(--alpha-blue-back);
      text-decoration: none;
      color: var(--alpha-white);
      font-size: 16px;
}

.error-message .back-home-btn:hover {
     border: 1px solid var(--alpha-blue-back);
     background: transparent;
     color: var(--alpha-blue-back);
     gap: 0.85rem;
     width: 160px;
}