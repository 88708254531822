.home-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--alpha-blue-back);
    gap: 4rem;
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 99999999;
}
.home-navbar .basket-badge, .basket-badge {
      position: relative;
}
.home-navbar .basket-badge img, .basket-badge img  {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
}
.home-navbar .basket-badge .item-count,.basket-badge .item-count {
    position: absolute;
    z-index: 9999;
    width: 20px;
    height: 20px;
    background: var(--alpha-blue-back);
    color: var(--alpha-green);
    font-size: 12px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    border-radius: 50%;
    top: -1.3rem;
    right: -2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigation-items .cart-mobile-icons {
     display: flex !important;
     align-items: center !important;
     gap: 3rem;
}
.navigation-items .mobile-hide-icons a{
      display: none !important;
}
.navigation-items .cart-mobile-icons a {
     margin-top: 0.5rem !important;
}
.hide-mobile-cart {
     display: none !important;
}
.accordion-open-btn {
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}
.accordion-active {
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
}

.home-navbar a, .home-navbar .my-profile{
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    color: var(--alpha-white) !important;
     cursor: pointer;
     text-decoration: none;
     transform: 0.5s ease;
}

.home-navbar .my-profile .caret {
    rotate: 0deg;
    transition: rotate 0.3s ease;
}
.home-navbar .my-profile .caret.rotate-caret {
    rotate: 180deg;
    transition: rotate 0.3s ease;
}
.home-navbar .active-link {
    color: var(--alpha-white) !important;
    font-weight: 600;
    border: none !important;
}
.home-navbar a:hover,  .home-navbar .my-profile:hover{
    color: var(--alpha-blue-back) !important;
    font-weight: 600;
}
.navigation-items .nav-btn {
     cursor: pointer;
     display: block;
     opacity: 1;
     color: var(--alpha-80);
     font-size: 1.8rem;
}
.nav-close-btn {
    color: var(--alpha-white) !important;
    position: absolute;
    top: 2%;
    right: 4%;
}
.navigation-items .responsive_nav {
    transform: none;
}
.navigation-items .shop-btn{
    display: block;
    border: none;
    background: var(--alpha-green);
    border-radius: 6px;
    padding: 0.3rem 0.8rem;
    color: var(--alpha-blue-back);
    font-weight: 500;
}
.navigation-items .shop-btn a{
    color: var(--alpha-blue-back);
}
.home-section {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-section .banner__section {
    width: calc(100% - 0.5rem);
    padding: 2rem 0;
    background: linear-gradient(90deg, #FBFBFB 0%, rgba(246, 246, 246, 0) 87.75%), url("../../../assets/bg-alpha.svg");
    background-position: right center;
    background-size: cover;
}
.home-section .container {
    display: flex !important;
    justify-content: center;
}
.home-section-label {
      position: absolute;
      width: 90%;
      display: flex;
      flex-direction: column;
      right: 0%;
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
      padding: 1.5rem;
      background: rgba(114, 255, 201, 0.8);
      border-radius: 12px;
}
.home-section-label h1 {
    width: 100%;
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
    text-transform: capitalize;
}
.home-section-label h4 {
    width: 100%;
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-size: 18px;
    color: var(--alpha-blue-back);
}
.home-section-label p {
    width: 95%;
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: var(--alpha-80);
    opacity: 0.75;
    text-align: justify;

}

.our-category-section {
    width: 100%;
    background: url("./../../../assets/bg-category.png");
    min-height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2.5rem 1rem;
}
.our-category-section .category-container  {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
   
}
.our-category-section .category-container h3 {
    font-size: 20px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back)
}
.our-category-section .category-container p {
    color: var(--alpha-40);
    font-size: 15px;
}
.category-container .categories-list {
     display: grid;
     gap: 1rem;
     grid-template-columns: 1fr;
     padding: 1rem;
}
.category-container .categories-list > div {
    width: 100%;
    height: 100px;
}
.category-container .categories-list a {
     text-decoration: none;
}
.category-container .categories-list .elect-cat {
     background: url("./../../../assets/elect-bg.svg") no-repeat;
     background-position: center;
     background-size: cover;
}
.category-container .categories-list .phones-cat {
     background: url("./../../../assets/phones-bg.svg") no-repeat;
     background-position: center;
     background-size: cover;

}
.category-container .categories-list .kids-cat {
    background: url("./../../../assets/babies-bg.svg");
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .shoes-cat {
    background: url("./../../../assets/shoes-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .clothing-cat {
    background: url("./../../../assets/clothings-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .automobile-cat {
     background:  url("./../../../assets/accessories-bg.svg") no-repeat;
     background-position: center;
     background-size: cover;

}
.category-container .categories-list .hairs-cat {
    background: url("./../../../assets/hair-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .home-cat {
    background: url("./../../../assets//home-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .grocery-cat{
    background: url("./../../../assets/grocery-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
}
.category-container .categories-list .elect-cat .elect-section {
      width: 95%;
      height: 100%;
      background: url("./../../../assets/electronic.svg") no-repeat;
      background-size: contain;
      background-position: right;
      display: flex;
      align-items: center;
      padding: 1.5rem;
      
}
.category-container .categories-list .phones-cat .phone-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/phones.svg") no-repeat;
    background-size: contain;
    background-position: right bottom;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    
}
.categories-list .kids-cat .kids-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/kids.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .shoes-cat .shoe-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/shoe.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .clothing-cat .clothing-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/clothing.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .automobile-cat .accessory-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/car.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .hairs-cat .hair-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/hair.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}

.categories-list .home-cat .home-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/kitchen.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .home-cat .kitchen-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/kitchen.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}
.categories-list .grocery-cat .grocery-section {
    width: 95%;
    height: 100%;
    background: url("./../../../assets/Groceries.svg") no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    padding: 1.5rem;  
}


.category-container .categories-list .elect-cat .elect-section p, .category-container .categories-list .phones-cat .phone-section p, .category-container .categories-list .kids-cat .kids-section p, .category-container .categories-list .shoes-cat .shoe-section p, .category-container .categories-list .clothing-cat .clothing-section p, .category-container .categories-list .automobile-cat .accessory-section p, .category-container .categories-list .hairs-cat .hair-section p, .category-container .categories-list .home-cat .kitchen-section p, .category-container .categories-list .grocery-cat .grocery-section p {
     width: 10.2rem;
     text-align: left;
     margin-top: 0.5rem;
     font-family: "Inter",sans-serif;
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 20px;
     color: var(--alpha-blue-back);
}


.women-downloading {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
}
.alpha-description-comp {
     width: 100%;
     padding: 3rem 1rem;
}
.alpha-description-comp .alpha-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
}
.alpha-verify-merchants .header, .alpha-seamless-shopping .header, .alpha-easy-secure .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}
.alpha-verify-merchants p, .alpha-seamless-shopping p, .alpha-easy-secure p {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.5px;
    padding-top: 1rem;
    color: #6b7c93;
}
.alpha-verify-merchants .header > div:first-child {
    color: var(--alpha-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #F1AF66;
    display: grid;
    place-items: center;
}
.alpha-verify-merchants .header > div:last-child {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.5px;
    color: #F1AF66;
}
.alpha-seamless-shopping .header > div:first-child {
    color: var(--alpha-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FB535E;
    display: grid;
    place-items: center;
}
.alpha-seamless-shopping .header > div:last-child {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.5px;
    color: #FB535E;
}
.alpha-easy-secure .header > div:first-child {
    color: var(--alpha-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FF8E70;
    display: grid;
    place-items: center;
}
.alpha-easy-secure .header > div:last-child {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.5px;
    color: #FF8E70;
}
.pt-desc {
    padding: 0 !important;
}
.search-result-display {
      position: relative;
      width: 93%;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin: auto;
      margin-top: -2.6rem;
      z-index: 5;
      border-radius:8px;
      background: var(--alpha-white);
      box-shadow: 1px -1px 8px 0px rgba(0,0,0,0.75);
     -webkit-box-shadow: 1px -1px 8px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 1px -1px 8px 0px rgba(0,0,0,0.75);
     padding: 1rem;
}
.search-result-display .item-link-to-alpha {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 1rem;
     width: 100%;
     padding: 1rem 0;
}
.search-result-display .item-link-to-alpha .item-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.search-result-display .item-link-to-alpha .item-section .product-image {
    width: 100%;
    height: 100%;
}
.search-result-display .item-link-to-alpha .item-section .qr-image {
     width: 100%;
     height: 100%;
}
.search-result-display .item-link-to-alpha .item-section .other-details{
     width: 100%;
     height: 100%;
}
.search-result-display .item-link-to-alpha .item-section .other-details div {
     display: flex;
     justify-content: space-between;
     align-items: flex-start;
     font-family: "Inter",sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
    
}
.search-result-display .item-link-to-alpha .item-section .other-details div:nth-child(n+2){
    margin-top: 1rem;
}
.search-result-display .item-link-to-alpha .item-section .product-image img {
     width: 100%;
     
}
.search-result-display .item-link-to-alpha .item-section .qr-image img {
    width: 100%;
}
.search-result-display .item-link-to-alpha .item-section .qr-image  div {
    margin-left: 1rem;
}
.search-result-display .btn-prod {
     border: 1px solid var(--alpha-blue-back);
     display: flex;
     align-items: center;
     justify-content: center;
     width: 10rem;
     height: 3rem;
     background: var(--alpha-blue-back);
     border-radius: 8px;
     cursor: pointer;
     transition: 0.5s all ease-in;
}
.search-result-display .btn-prod a {
    text-decoration: none !important;
    color: var(--alpha-white);
}
.search-result-display .btn-prod:hover {
    background: var(--alpha-white);
}
.search-result-display .btn-prod:hover a {
     color: var(--alpha-blue-back);
}
.close-search-result {
    position: absolute;
    right: 0.25rem;
    top: 0;
    color: var(--alpha-blue-back);
    cursor: pointer;
    transition: all 1s ease-in;
}
.search-result-display .item-link-to-alpha .item-section .other-details div span:first-child {
    width: 35%;
    letter-spacing: 0.5px;   
    color: var(--alpha-blue-back);
    font-weight: 700;
}
.search-result-display .item-link-to-alpha .item-section .other-details span:last-child {
    width: 60%;
    color: var(--alpha-40);
}
.close-search-result:hover {
     color: var(--alpha-red);
}


.top-btn {
      position: fixed;
      bottom: 5%;
      right: 3%;
      z-index: 99999;
      color: var(--alpha-blue-back);
      background: var(--alpha-green);
      cursor: pointer;
      font-size: 24px;
      width: 40px;
      height: 40px;
      border: 1px solid var(--alpha-blue-back);
      text-align: center;
      border-radius: 50%;
}
.top-btn:hover {
     animation: none;
     color: var(--alpha-white);
     background: var(--alpha-blue-back);
}
.home-navbar .user-modal {
    position: absolute;
    top: 55%;
    width: 70%;
    margin: auto !important;
    min-height: 80px;
    height: auto;
    border: 1px solid var(--alpha-10);
    z-index: 9999999999999 !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background: var(--alpha-white);
}
.home-navbar .user-modal button {
     width: 92%;
     height: 50px !important;
     font-family: "Inter", sans-serif;
     background: var(--alpha-green);
     border: 1px solid var(--alpha-green);
     border-radius: 8px;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     scale: 0.96;
     transition: scale 0.3s ease;
     color: var(--alpha-blue-back) !important;

    
}
.home-navbar .user-modal #signin-btn{
    width: 92%;
     height: 50px !important;
     font-family: "Inter", sans-serif;
     background: var(--alpha-green);
     border: 1px solid var(--alpha-green);
     border-radius: 8px;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     scale: 0.96;
     transition: scale 0.3s ease;
     color: var(--alpha-blue-back) !important;
    
}
.home-navbar .user-modal a:hover {
     scale: 1 !important;
}

.download-section.alpha-footer {
    width: 100%;
    position: relative;
    min-height: 250px !important;
    height: 100%;
    padding: 5rem 0px;
    background: rgb(242, 246, 247)
}

.download-section.alpha-footer .download-content {
     width: 95%;
     min-height: 429.42px;
     margin: auto;
     display: flex;
     flex-direction: column;
     gap: 1rem;
     justify-content: center;
     align-items: center;
}
.download-section.alpha-footer .download-content .alpha__download-sec {
     width: 301px;
     font-family: "Inter", sans-serif;
     display: flex;
     flex-direction: column;
     color: #333333;
     letter-spacing: 0.5px;
     gap: 1rem;
}
.download-section.alpha-footer .download-content .alpha__download-sec h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29.05px;
}
.download-section.alpha-footer .download-content .alpha__download-sec p {
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
}
.download-section.alpha-footer .download-content .alpha__download-sec .alpha-app-download-icons {
    display: flex;
    gap: 0.5rem;
}
.download-section.alpha-footer .download-content .alpha__download-sec .alpha-app-download-icons a {
     height: 45px;
     width: 151px;
}
.download-section.alpha-footer .download-content .alpha__download-sec .alpha-app-download-icons a img {
      width: 100%;
}
.download-section.alpha-footer .download-content .app-img {
    width: 300px;
}

.download-section.alpha-footer .download-content .app-img img {
     width: 100%;
}
.user-modal .modal-links {
     width: 100% !important;
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-top: 1rem;

}
.user-modal .modal-links a {
    color: var(--alpha-blue-back) !important;
}
.user-modal .modal-links .hr {
    height: 1px;
    width: 100%;
    margin-bottom: 0.5rem;
    background: var(--alpha-10);
}
.user-modal .modal-links .account-links-mobile {
    display: flex;
    flex-direction: column;
    font: 400;
    font-family: "Circular Std", sans-serif;
}
.user-modal .modal-links .account-links {
    display: none;
}
.user-modal .modal-links .account-links-mobile a:nth-child(2){
    margin-top: -0.5rem;
}

@media only screen and (max-width: 280px) {
    .download-section.alpha-footer .download-content .alpha__download-sec .alpha-app-download-icons a {
        width: 131px; 
    } 
    .download-section.alpha-footer .download-content .app-img {
        width: 258px;
    }
    .download-section.alpha-footer .download-content .alpha__download-sec {
          width: 95%;
    }    
}
@media only screen and (min-width: 768px){
    .navigation-items .cart-mobile-icons {
         display: none !important;
    }
    .navigation-items .mobile-hide-icons a {
          display: block !important;
    }
    .navigation-items .shop-btn-mobile{
        display: none;
    }
    .navigation-items .shop-btn{
        display: block;
        border: none;
        background: var(--alpha-blue-back);
        border-radius: 6px;
        padding: 0.3rem 0.5rem;
        color: white;
    }
    .hide-mobile-cart {
         display: block !important;
    }
    .home-navbar .active-link {
        color: var(--alpha-blue-back) !important;
    }
   
    .home-navbar {
        display: flex;
        align-items: center;
        justify-content: initial;
        flex-direction: initial;
        gap: .8rem;
        position: unset;
        top: unset;
        left: unset;
        width: unset;
        height: unset;
        background-color: unset;
        transform: none;
    }
    .home-navbar a, .home-navbar .my-profile {
        color: var(--alpha-80) !important;
        font-size: .7rem;
    }
    .navigation-items .nav-btn {
        display: none;
        opacity: 0;
    }
    .home-navbar .basket-badge .item-count {
        background: var(--alpha-blue-back);
        color: var(--alpha-green);
    } 
    .home-section-label {
         width: 43%;
         left: unset;
         right: 5%;
    }
    .home-section-label h1 {
        font-size: 2.1rem;
        width: 100%;
        line-height: 45px;
    }
    .home-section-label h4 {
        font-size: 1.4rem;
        line-height: 40px;
    }
    .home-section-label p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .our-category-section {
        padding: 5rem 2.5rem;
    }
    .our-category-section .category-container {
        line-height: 34px;
    }
    .our-category-section .category-container h3 {
        font-size: 24px;
    }
    .our-category-section .category-container p {
        font-size: 18px;
    }
    .category-container .categories-list {
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem 2rem;
   }
   .category-container .categories-list .automobile-cat .accessory-section p {
      width: 10.2rem;
   }
  .alpha-description-comp .alpha-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .alpha-description-comp .alpha-container{
       width: 90% !important;
       margin: auto;
  }
  .search-result-display {
       width: 61%;
  }
  .search-result-display .item-link-to-alpha .item-section {
       flex-direction: initial;
  }
  .search-result-display .item-link-to-alpha .item-section .product-image {
    width: 25%;
  }
    .search-result-display .item-link-to-alpha .item-section .qr-image {
        width: 25%;
    }
    .search-result-display .item-link-to-alpha .item-section .other-details{
        width: 45%;
    }
    .faq-accordion .item .faq-title h3, .faq-accordion .item .faq-title span{
        font-size: 18px;
    }
    .home-navbar .user-modal {
        top: 70%;
        right: 10.5%;
        width: 160px
    }
    
    .home-navbar .user-modal a {
        height: 40px !important;
    }
    .download-section.alpha-footer .download-content {
        width: 90%;
        justify-content: space-between;
        flex-direction: unset;
        gap: unset;
   }
   .download-section.alpha-footer .download-content .app-img {
        width: 358px;
   }
   .user-modal .modal-links .account-links-mobile {
    display: none;
    }
    .user-modal .modal-links .account-links {
        display: flex;
        flex-direction: column;
        font: 400;
        font-family: "Circular Std", sans-serif;
    }
    .user-modal .modal-links .account-links a:nth-child(2){
        margin-top: -0.5rem;
    }
   
}



@media only screen and (min-width: 1024px){
   
    .hide-on-tablet {
          display: block;
    }
    .home-section-label {
        background: initial;
        border-radius: none;
   }
   .hide-on-mobile {
    display: none;
}
   .home-page-alpha-search button {
      width: 25%;
      font-size: 0.8em;
   }
   .our-category-section {
         padding: 5rem 4rem;
    }
    .category-container .categories-list .automobile-cat .accessory-section p {
          width: 10.4rem;
    }

   .alpha-description-comp .alpha-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }
    .download-section.alpha-footer .download-content {
        width: 814px;
   }
   .home-navbar {
        gap: 1.5rem;
    }
    .home-navbar a, .home-navbar .my-profile {
        font-size: .9rem;
    }
}
@media only screen and (min-width: 1280px) {

    .category-container .categories-list {
        grid-template-columns: repeat(3, 1fr);
   }
   .our-category-section {
       padding: 5rem;
   }
   .home-navbar {
        gap: 2rem;
    }
    .home-navbar a, .home-navbar .my-profile {
        font-size: 1rem;
    }
}

