.store_section {
    min-height: 290px;
    width: 100%;
    box-sizing: border-box;
    background-image: url("../../../assets/store/banner.svg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
}
.store_banner_img {
    width: 120px;
    height: 120px;
    margin: -4rem auto 1rem auto;
    border-radius: 50%;
    border: 5px solid var(--alpha-green);  
}

.store_banner_img .store__logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
 
}
.store__details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.store__details h3 {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: var(--alpha-blue-back);
}

p.store__description {
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color:#333333;
    line-height: 20px;
    max-width: 80%;
    margin: auto;
 
}

.store_products {
    width: 95%;
    margin: auto;
    padding: 0rem 0px 1.5rem 0px;
    height: 100%;
    background: var(--alpha-white);
    margin-bottom: 1rem;
   
}
.store_products.store_products-mt {
     margin-top: -1.5rem !important;
}
.store_products .product__store-cards, .other__products-merchant .product__store-cards{
    border: none;
    transition: all 0.5s ease-in;
    scale: 0.92;
    border-radius: 8px;

    
}
.store_products .product__store-cards:hover, .other__products-merchant .product__store-cards:hover {
     padding-bottom: 0.5rem;
     padding-top: 0 !important;
     scale: 1;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.product_content {
    margin: 1em auto;
}
.product_content  a {
    text-decoration: none !important;
}

.shop_img {
    width: 100%;
    height: 180px;
    background: var(--alpha-white);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 8px;   
}

.shop_img .product_shop_img {
    width: 100%;
    height: 100%;
    border-radius: 8px;

}

.product_content p {
    width: 106px;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
     overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: #4D4D4D;
    margin:  1em auto 0;
}
.add__to-cartBtn {
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--alpha-blue-back);
    border-radius: 5px;
    background: transparent;
    color: var(--alpha-blue-back);
    text-transform: uppercase;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}
.add__to-cartBtn:hover {
     background: var(--alpha-blue-back);
     color: var(--alpha-green);
}
.product_content span{
    min-width: 106px;
    max-width: 120px !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 0.85rem !important;
    line-height: 16px;
    text-align: center;
    margin: auto;
    color: var(--alpha-blue-back);
}
   .delivery_info {
        width: 95%;
        height: 82px;
        background: var(--alpha-white);
        margin-top: -2rem;
        margin-left: auto;
        margin-right: auto;
        overflow-x: scroll;
        border: 1px solid #EDEDED;
        border-radius: 6px;
        display: none !important;
        gap: 1rem;
        align-items: center;
        padding: 0 0.5rem;
    }
  
    .services-desc {
       display: flex;
       align-items: center;
       justify-content: center;
       width: 100%;   
       margin: auto;
       position: relative;
       gap: 0.5rem;
    }
   .services-desc img {
        width: 20px;
        height: 18px;
    }
    .service-seperator {
        width: 1px;
        height: 35px;
        background:  #EDEDED;
    }
    .services-desc .service-rendered {
        font-family: "Circular Std",sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.02em;
        color: var(--alpha-blue-back);
    }
.pagination {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.next-btn, .prev-btn {
    font-family:"Circular Std",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    height: 15px;
    cursor: pointer;
}
.prev-btn {
    width: 33%;
}
.next-btn {
    width: 27%;

}
.pagination .item-pages {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1em;
  
}

.pagination .item-pages span {
    height: 15px;
    font-family: "Circular Std",sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;
    text-transform: uppercase; 
    color: var(--alpha-40);
    text-align: center;
    height: 100%;
    width: 100%;
}
.chevron {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;
    height: 15px;
    margin-top: -2px;
}

.btn-disabled {
    color: var(--alpha-40);
    font-size: 11px;
}
.btn-active {
    color: var(--alpha-blue-back);
    font-size: 11px;
}
.next-btn:last-child, .prev-btn:last-child {
    margin-left: 1.5em;
}
.active-store-page {
    border: 1px solid var(--alpha-blue-back) !important;
    border-radius: 2px;

}
.load-more-btn {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 1rem auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none !important;
}

.load-more-btn div {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
}
.load-more-btn div:last-child {
    margin-left: .5rem;
}
.failed-alert {
    padding: 1rem;
    border: 1px solid var(--alpha-red);
    margin: 1rem auto;
    width: 96%;
    text-align: center;
    font-size: 14px;
    font-family: "Circular Std",sans-serif;
    font-weight: 900;
    color: red;
    border-radius: 5px;
}
.store_link_alpha {
    text-decoration: none !important;
}

.prod__from-store {
    background: var(--alpha-white);
    font-family: 'Circular Std',sans-serif;
    font-style: normal;
    height: 100%;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.02em;
    width: 95%;
    margin: 2rem auto;
    display: flex;
    align-items: center;
    position: relative;
}

.prod__from-store  .products__instore-label {
    height: 40px;
    display: none;
    position: relative;
    padding-left: 1rem;
    color: var(--alpha-blue-back);
    align-items: center;
}
.prod__from-store  .products__instore-label:before {
    content: "";
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border: 2px solid var(--alpha-green);
    position: absolute;
}
.prod__from-store .all-categories {
    display: none;
  
}
.prod__from-store .search__category-mobile  {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      
}
.prod__from-store .search__category-mobile .categories {
   width: 60%;
}
.prod__from-store .search__category-mobile .categories select {
     width: 100%;
     height: 40px;
     border-radius: 5px;
     padding: 0.5rem;
     border: 3px solid var(--alpha-blue-back);
     background: var(--alpha-blue-back);
     color: var(--alpha-white);
}
.prod__from-store .search__category-mobile .category-label {
   font-size: 14px;
   font-weight: 600;
   color: var(--alpha-blue-back);
}
.unavailable-product {
    display: flex;
    width: 100%;
    height: 100px;
    font-size: 18px;
    font-weight: bold;
    font-family: "Circular Std",sans-serif;
    color: var(--alpha-blue-back);
    align-items: center;
    justify-content: center !important;
    text-align: center;
}
.navigation-items .store-logo{
    width: 50px !important;
    height: 50px !important;
    border: 2px solid var(--alpha-green);
    border-radius: 50%;
    padding: 0.125rem;
}
.navigation-items .store-logo img {
     width: 100%;
     height: 100%;
     object-fit: contain;
     border-radius: 50%;
}
.prod__from-store button { 
     display: none
}
.store__show-more, .nomore__show-more {
    width: 95%;
    font-family: "Inter",sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    background: transparent;
    color: #979797;
    margin: 0px auto 2rem auto;
    height: 50px;
    border: 1.5px solid #F1F1F1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease;
    gap: 0.25rem;
}
.store__show-more, .store__show-more:hover {
    background: var(--alpha-green);
    color: var(--alpha-blue-back);
    border-color: var(--alpha-green) ;
}


.store__show-more .showmore-rotate {
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}
.back__btn-detail {
     width: 95%;
     cursor: pointer;
     margin: 1rem auto;
     display: flex;
     align-items: center;
     gap: 0.25rem;
     color: #000000;
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
}

.product__name-qty {
     display: flex;
     width: 100%;
     align-items: center;
     justify-content: space-between;
}

.back__btn-button {
     width: 95%;
     margin: 0.5rem auto;
     cursor: pointer;
     display: flex;
     align-items: center;
     gap: 0.5rem;
     line-height: 24px;
     font-size: 18px;
     color: var(--alpha-blue-back);
     text-decoration: none;
}

.back__btn-button .back__text {
    font-weight: 500;
    margin-top: -2px;
    
}
.other__product-section {
     width: 95%;
     margin: auto auto 1.5rem auto;
     
}
.other__products {
    display: flex;
    align-items: center;
    width: 100%;
    
}
.other__products div:last-child {
    font-weight: 450;
    font-family: "Circular Std",sans-serif;
    font-size: 18px;
    line-height: 22.77px;
    color: var(--alpha-blue-back);
}
.other__products div:first-child {
    height: 50px;
    width: 4px;
    border-radius: 2px;
    background: var(--alpha-green);
    margin-right: 1rem; 
   
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
@media only screen and  (min-width: 48em) {
    .delivery_info {
         display: flex !important;
    }
    .navigation-items .store-logo{
        width: 60px !important;
        height: 60px !important;
    }
    .shop_img {
        border-radius: 8px;
        background: transparent !important;
        box-shadow: none;
        height: 220px;
    }
    .product_content {
        padding-bottom: 1rem;
    }
    .prod__from-store {
         max-width: 95%;
         margin: 3rem auto;
         gap: 2rem;
         justify-content: space-between;
    }
    .prod__from-store .all-categories {
        display: flex;
        width: calc(748px - 205px);
        gap: 2rem;
  
    }
 
    .prod__from-store .all-categories .product_categories {
          display: flex;
          font-family: "Circular Std",sans-serif;
          width: 90% !important;
          align-items: center;
          gap: 2rem;
          
    }
    .prod__from-store .product_categories .products__list-category {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem !important;
          overflow-x: auto;

          position: relative;
    }
    .prod__from-store .products__list-category .category__slide-snap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    
    }
  
    .prod__from-store .product_categories .products__list-category .category-name {
        font-family: "Circular Std",sans-serif;
        font-weight: 450;
        white-space: nowrap;
        width: auto !important;
        margin-right: unset !important;
        font-size: 16px;
        line-height: 20.24px;
        color: var(--alpha-80);
        
    }
    .prod__from-store .product_categories .products__list-category .category-name:nth-child(n+2){
         margin-left: 1rem !important;
    }

    .prod__from-store .search__category-mobile {
         display: none;
    }
    .col-height {
        height: 100%;
    }
     
    
    .store_banner_img {
        width: 150px;
        height: 150px;
        margin: -4rem 0 0 4rem !important;
        border-radius: 50%;
        border: 5px solid var(--alpha-green);  
       
    }
    
    .store_banner_img .store__logo {
        width: 100%;
        height: 100%;
     
    }
    .store_owner_details {
        max-width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
       
        
    }
    .store__details h3 {
        font-size: 18px;
    }
    .services-desc img {
        width: 30px;
        height: 25px;
    }
    .services-desc:not(:last-child)::after {
        content: "";
        position: absolute;
        right: 0;
        height: 50px;
        width: 1px;
        background:  #EDEDED;
    }
    .product_content p {
        width: 95%;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;  
        letter-spacing: -0.02em; 
        color: #4D4D4D;
    }
    .product_content span {
        font-weight: 500;
        font-size: 1.25rem !important;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.02em;
    }
    .pagination {
        display: none;
    }
    .load-more-btn {
        display: flex !important;
    }
.prod__from-store .list-categories {
    font-family: "Circular Std",sans-serif;
    font-weight: 450;
    color: var(--alpha-blue-back);
    font-size: 18px;
    line-height: 22.77px;
}
    .prod__from-store  .products__instore-label {
          display: flex;
          width: 205px;
          white-space: nowrap;
    }
    .prod__from-store button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 30px;
        height: 30px;
        background: #fff;
        color: var(--alpha-blue-back);
        border: none;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        border-radius: 30px;

        
  }
  .category-name.alpha-red {
    font-weight: 700;
      color: #ED3237 !important;
  }
 .category__slide-snap .next-btn {
     z-index: 9999999999;
     font-weight: 800;
     font-size: 16px;
     right: 0;
     color: #ED3237;
     box-shadow: rgba(0, 0, 0, 0.5);
 }
 .category__slide-snap .prev-btn {
    z-index: 9999999999;
    font-weight: 800;
    font-size: 16px;
    left: 0;
    color: #ED3237;
    box-shadow: rgba(0, 0, 0, 0.5);
 }
 .store_products {
       width: 95%;
       margin-left: auto;
       margin-right: auto;
       margin-top: -1.5rem;
      margin-bottom: 1rem;
 }
  
   
}

@media only screen and (min-width: 51.25em){
    .prod__from-store .all-categories .product_categories {
        width: 95% !important;
   
  }
}

@media only screen and (min-width: 64em){
    .prod__from-store .all-categories {
        width: 748px;
  
    }
    .services-desc .service-rendered {
        font-size: 18px;
    }
    .store__details h3 {
        font-size: 24px;
    }
    
    .store__details .check-icon {
        font-size: 22px;
        margin: -5px 0px 0px 1px;
    }
    p.store__description {
        font-size: 14px;
        line-height: 24px;
    }
    .store_banner_img {
        width: 170px;
        height: 170px;
        margin: -4.5rem 0 0 4rem !important; 
       
    }
    .store_owner_details {
        max-width: 95%;
        
    }
    .prod__from-store {
        max-width: 95%;
        position: relative;
    }
    
    .delivery_info {
         width: 95%;
        overflow-x: hidden;
    }
    .prod__from-store .all-categories .product_categories {
        width: 95% !important;
   
  }
  
}


@media only screen and (min-width: 80em) {
    .store_owner_details, .store_products-merchant, .store_products {
        max-width: 1080px;
        
    }
    .prod__from-store {
        max-width: 1080px;
    }
    
    .delivery_info {
        max-width: 1080px;
    }
    .prod__from-store .all-categories .product_categories {
        width: 100% !important;
   
  }
  .store__show-more,  .store_products, .nomore__show-more, .other__products-merchant {
     max-width: 1080px;
  }
   .back__btn-detail {
       max-width: 1080px;
   }
   .back__btn-button  {
      max-width: 1080px;
   }
   .other__product-section {
      max-width: 1080px !important;
      width: 100% !important;
   }
}