.mobile-checkout-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--alpha-blue-back);
    opacity: 0.8; 
    z-index: 4999;
}

.mobile-checkout-screen {
    width: 95%;
    height: auto;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 0 1.5rem 0;
    z-index: 5100;
    overflow-y: auto;
    border-radius: 12px;
    transition: all 0.3s ease-in;
    animation: fadeInUp 0.8s;
}

.close-overlay-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    font-family: bold;
    cursor: pointer;
    color: #333;
    z-index: 5100 !important;
    color: var(--alpha-red);
}

.mobile-checkout-screen h1 {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--alpha-blue-back);
}
.mobile-checkout-screen h2 {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;    
    color: var(--alpha-blue-back);
}
.available-sizes, .available-colors {
    padding: 0 .5rem;
}
.mobile-color-select {
     padding: 0 1rem !important;
}
.alpha-price-section {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

}
.hide-checkout-modal {
    display: none;
}
.prefered-mobile {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;
    color: var(--alpha-blue-back);
}

.mobile-gift-wrapping {
   border-bottom: 1px solid #EFEFEF;
   border-top: 1px solid #EFEFEF;
   padding: 1rem 1.8rem;
}
.gift-wrap-text-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gift-wrap-label-mobile {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
 }
 .select-gift {
      width: 80%;
      display: flex;
      flex-direction: column;
 }

 .select-gift span:first-child {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.25px;
   color: var(--alpha-blue-back)
 }
 .select-gift span:last-child {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: justify;
    letter-spacing: 0.25px;
    color: var(--alpha-40);
 }
 .gift-wrap-input-mobile {
     border: 5px solid var(--alpha-40);
     margin-right: -3rem;
     display: none;
 }
.checkbox__checkbox {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid var(--alpha-black);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 2px;
}
.checkbox__checkbox::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--alpha-blue-back);
    transform: scale(0);
    transition: transform 0.15s;
}
.gift-wrap-input-mobile:checked + .checkbox__checkbox::after {
    transform: scale(1);
}


 .qty-btn-section-phone {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
 }


 .btn-minus-mobile, .btn-plus-mobile {
    width: 50px;
    height: 50px;
    background: var(--alpha-green);
    border: 1px var(--alpha-green);
    border-radius: 8px;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--alpha-blue-back);
 }
.btn-qty-mobile {
    width: 150px;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--text-black);
}
 .item-add-disabled-mobile {
    background: var(--alpha-white-alt) !important;
    border: 1px solid var(--alpha-white-alt) !important;
    color: gray;
  } 
  .phone-checkout-btn button,.phone-buynow-btn button {
    width: 330px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--alpha-green);
    border-radius: 5px;
    margin: auto;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center !important;
    letter-spacing: 1px;
    color: var(--alpha-blue-back);
    border: 1px solid var(--alpha-green);
  }
  .phone-buynow-btn button {
       background: var(--alpha-blue-back) !important;
       color: var(--alpha-white) !important;
  }
  .delivery-section-mobile {
      width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      
  }
  .delivery-section-mobile div:nth-child(1) {
        font-family: "Circular Std", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: var(--alpha-blue-back);
        cursor: pointer;
  }
  .delivery-section-mobile div:nth-child(2){
        margin-left: 64px;
        font-family: "Circular Std", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: var(--alpha-blue-back);
  }
  .select-options-mobile {
       width: 100%;
  }
  .option-delivery-select {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 1.5rem;
  }
  .img-container-mobile {
      background: #72FFC933;
      text-align: center;
      padding: .6rem .8rem;
      margin-right: 1rem;
      border-radius: 50%;
  }
.img-container-mobile .delivery-bike {
    color: var(--alpha-blue-back) !important;
    font-size: 20px;
}

.door-img-container-mobile {
    background: #72FFC933;
    text-align: center;
    padding: .6rem .8rem;
    border-radius: 50%;
    margin-right: 1rem;
}
.door-img-container-mobile .delivery-bike {
  color: var(--alpha-blue-back) !important;
  font-size: 20px;
}
.mobile-delivery-hr {
    border-bottom: 1px solid rgba(194, 208, 104, 0.4);
    height: 1px;
    width: 100%;
    padding: .5rem  0;
    margin-bottom: 1rem;
}
.delivery_details {
      width: 100%;
      font-size: 12px;
}
.delivery_details .delivery_title {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);

}

.delivery_details .delivery_note {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #8E91A2;
    margin-right: 1rem;
}
.door-delivery-title-disabled {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #c8c9ca;
    width: 100%;
}
.door-delivery-note-disabled {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #c9c9c9;

}
.radio {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.radio__input {
    display: none;
}
.radio__radio {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid var(--alpha-black);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2px;
}
.radio__radio::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--alpha-blue-back);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
}
.radio__input:checked + .radio__radio::after {
    transform: scale(1);
}

.proceed-to-pay button {
    width: 344px;
    height: 50px;
    background: var(--alpha-green);
    border: 1px solid var(--alpha-green);
    border-radius: 5px;
    margin: 3rem auto;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    color: var(--alpha-blue-back);
    
}
.delivery__details__mobile {
    width: 100%;
    height: 100%;
    margin: 1rem auto;
    background: var(--text-white) !important;
}

.back__navigation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(194, 208, 104, 0.4) !important;
    padding: .5rem 1rem;
}
.back__navigation__mobile {
    width: 100%;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;  
}
.checkout__header {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: var(--alpha-blue-back);
}
.back__navigation__mobile span:nth-child(2) {
    margin-left: 30%;
}
.details_body {
    width: 100%;
    height: 100%;
    padding: 1rem;
}
.back__navigation span{
   color: var(--alpha-blue-back);
   font-family: "Circular Std", sans-serif;
   font-style: normal;
   font-weight: bold;
}
.delivery-details-mobile-input {
    display: flex;
    flex-direction: column;
}
.delivery-details-mobile-input input {
    height: 50px;
}
.delivery-details-mobile-input textarea {
    height: 100px;
}
.delivery-details-mobile-input textarea, .delivery-details-mobile-input input {
    padding: .5rem;
    border: 1px solid rgba(51, 51, 51, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
}

.delivery-details-mobile-input textarea:focus, .delivery-details-mobile-input input:focus{
    outline: none;
}
.delivery-details-mobile-input label {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: var(--alpha-blue-back);
}
.input-text-mobile {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: var(--alpha-blue-back);
  }
.door-delivery-details {
    width: 100%;
    height: 100%;

    margin: 1rem 0 5rem 0;
}
.mobile-door-style {
    padding-bottom: 4rem;
}
.card_mobile_header {
    background: var(--text-white) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(194, 208, 104, 0.4) !important;
    padding: 1rem .5rem;
}
.option-delivery-details {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    padding: 1rem;
}
.card__header .checkout-header {
   font-family: "Circular Std", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   line-height: 18px;
   letter-spacing: 0.5px;
   color: var(--alpha-blue-back);
  
}

.card__header .close-modal {
     font-family: "Circular Std", sans-serif;
     font-style: normal;
     font-weight: 400;
     color:#000000;
     font-size: 14px;
     cursor: pointer;
}
.card_mobile_title {
   font-family: "Circular Std", sans-serif;
   font-style: normal;
   font-weight: 450;
   font-size: 12px;
   line-height: 15px;
   letter-spacing: 0.5px;
   color: var(--alpha-blue-back);
} 
.card__header_pay {
    width: 100%;
    padding: 2rem 1rem 1rem 1rem;
    display: flex;
    align-items: center;

}
.card__header_pay.header-pay {
    padding: 0.5rem 1rem !important;
}
.card__header_pay span {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;    
    letter-spacing: 0.25px;
    color: var(--alpha-blue-back);
}
.card__header_pay span:nth-child(2) {
     margin-left: 30%;
}
.door_delivery_radio_label {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.door_delivery_radio_label.pickup-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.door_delivery_radio_label.pickup-mobile .door_delivery_radio {
     display: flex !important;
     accent-color: var(--alpha-blue-back);
     height: 1.2rem !important;
     width: 1.2rem !important;
     margin-left: 4px;
}
.door_delivery_radio {
    display: none;
}

.door-img-container-disabled {
    background: rgba(218, 219, 219, 0.2) !important; 
    text-align: center;
    padding: .6rem .8rem;
    border-radius: 50%;
  }
  .door-media-img-disabled{
    color: rgba(129, 139, 139, 0.2) !important;
  }
  .door-img-container-disabled-mobile {
       margin-right: 1rem;
  }
  .delivery-details-inactive {
      width: 80%;
  }
  .door_delivery_radio_disabled {
      width: 1.25em;
      height: 1.25em;
  }
  .door_mobile_delivery__radio_btn {
      width: 1.25em;
      height: 1.25em;
      border: 2px solid var(--alpha-blue-back);
      border-radius: 50%;
      padding: 2px;
      box-sizing: border-box;
  }
  .door_mobile_delivery__radio_btn::after {
        content: "";
        width: 100%;
        height: 100%;
        background: var(--alpha-blue-back);
        display: block;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 0.15s;
  }
  .door_delivery_radio:checked + .door_mobile_delivery__radio_btn::after {
     transform: scale(1);
}
.process-btn-width {
    padding: 0 1rem !important;
}

.mobile-payment-style {
    width: 100%;
}
.payment_title_card {
    width: 100%;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
}
.payment__type__mobile {
    width: 100%;
    height: 100% !important;
    padding: 0 1rem;
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    /* flex-direction: column; */
}

.payment_section_mobile {
    width: 100%;
    display: inline-flex;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
}
.payment_section_mobile.type-mobile {
     display: flex !important;
     justify-content: space-between !important;
}
.payment_section_mobile.type-mobile .paystack__radio-cod{
    height: 1rem;
    width: 1rem;
}
.payment_section_mobile img {
    margin-left: .5rem;
    width: 35px;
}
.flutterwave_input_input, .paystack_input_input, .cod_input_input, .flutterwave_input_input_service, .paystack_input_input_service, .cod_input_input_service {
    display: none;
}
.flutterwave_radio_button, .paystack_radio_button, .cod_radio_button, .flutterwave_radio_button_service, .paystack_radio_button_service, .cod_radio_button_service {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid var(--alpha-blue-back);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2px;
}
.paystack_radio_button::after, .flutterwave_radio_button::after, .paystack_radio_button_service::after, .flutterwave_radio_button_service::after{
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--alpha-blue-back);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
}

.cod_radio_button::after, .cod_radio_button_service::after  {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--alpha-blue-back);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
}

.paystack_input_input:checked + .paystack_radio_button::after, .flutterwave_input_input:checked + .flutterwave_radio_button::after,
.paystack_input_input_service:checked + .paystack_radio_button_service::after, .flutterwave_input_input_service:checked + .flutterwave_radio_button_service::after
{
    transform: scale(1);
}
.cod_input_input:checked + .cod_radio_button::after, .cod_input_input_service:checked + .cod_radio_button_service::after {
    transform: scale(1);
}
.mobile_pickup_radio {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid var(--alpha-blue-back);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2px;
}

.mobile_pickup_radio::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: var(--alpha-blue-back);
    border-radius: 50%;
}
.success__message_container {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     background: var( --alpha-bg-color);
     justify-content: center;
     text-align: center;
    
}
.success__message_container.mobile-success {
    height: 100% !important;
    background: var( --alpha-white) !important;
}

.transaction_success {
    padding: 4rem 1rem 1rem 1rem;
}

.loader-page-mobile {
    width: 200px;
    background: var(--alpha-white);
    height: 200px;
    min-height: calc(100vh  - 250px);
    border-radius: 5px;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;


}
.success_image .success_icon{
      width: 47px;
      height: 47px;
      color: var(--alpha-green);
      margin-bottom: 1rem;
}
.success_image .failed_icon{
    width: 47px;
    height: 47px;
    color: var(--alpha-red);
    margin-bottom: 1rem;
}
.success_text {
    width: 80%;
    margin: 1rem auto;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);

}
.tracking_number {
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
}
.success_message {
    width: 80%;
    margin: 1rem auto;
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #8E91A2;
}
.store-link a{
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: var(--alpha-blue-back);
    margin: 2rem auto 1rem auto;

}
.content_footer_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.download_app_links {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.download_app {
    width: 100%;
    height: auto;
}
.appstore, .playstore {
    width: 150px;
    margin-top: -3rem;
}

.download_app_links > div:not(:last-child) {
    margin-right: 10%;
  }
  .download_hr {
      margin-top: 4rem;
  }
  .download_placeholder {
        margin-top: 2rem;
        font-family: "Circular Std", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 38px;
        letter-spacing: -0.743307px;
        color: var(--alpha-40);
    }

.service-center-mobile {
    width: 100%;
    height: 100%;
    min-height: 200px !important;
    padding: 3rem 1rem 1rem 1rem;
    background: var(--alpha-bg-color) !important;
}
.service__header  {
    background: var(--text-white) !important;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}
.service__header .checkout-header {
     margin-left: 4rem;
     margin-top: 10px;
}
.service__header .checkout-header, .service__header .service_back_nav {
   font-family: "Circular Std", sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   line-height: 18px;
   letter-spacing: 0.5px;
   color: var(--alpha-blue-back);
}
.out-of-stock {
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    gap: 0.5rem;
}
.out-of-stock-text {
    color: var(--alpha-red);
    font-family: "Circular Std", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-decoration: line-through;
}
@media only screen and (max-width: 280px){
    .phone-checkout-btn button,  .phone-buynow-btn button {
        width: 270px;
    }
    .mobile-page-content .product-price-for-mobile {
        font-size: 14px;
    }
    .star-container, .total-reviews {
        font-size: 12px !important;
    }
}
@media screen  and (min-width: 48em) {
    .mobile-checkout-screen {
        display: none;
    } 
}

@keyframes fadeInUp{
   0% {
       opacity: 0;
       transform: translate(0%,-50px);

   }
   100% {
       opacity: 1;
       transform: translate(-50%,-50%);
   }
}