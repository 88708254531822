.account__delete-page {
    padding: 0 0 2rem 0;
    width: 100%;
    position: relative;
}
.account__delete-bg {
    background: url("../../../assets/store/banner.svg") no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    padding: 2rem 0;
    width: 100%;

}
.account__delete-bg  .account__delete-container{
    width: 95%;
    display: flex;
    position: relative;
    justify-content: center;
    height: 100%;
    margin: auto;
    padding: 1.5rem 0 !important;
}

.account__delete-bg .account__name-display .account-title {
    font-family: 'Rebond Grotesque', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    letter-spacing: 0.5px;
    color: var(--alpha-blue-back);
    /* margin-top: 1rem; */
}
.account__delete-bg .account__name-display {
    margin-top: -1.5rem;
}
.account__delete-page .float-h {
     height: 500px !important;
}
.account__delete-page .delete-form {
     width: 95% !important;
     display: flex;
     flex-direction: column;
     justify-content: center;
     gap: 1rem;
     margin-left: auto !important;
     margin-right: auto !important;
     min-height: 300px !important;
     height: 500px;
     margin-top: -7rem;
     font-family: "Inter", sans-serif;
}
.account__delete-page .user__acount-form {
      width: 100% !important;
      height: 56px !important;
      border: 1px solid var(--alpha-40);
      border-radius: 8px;
      padding: 1rem 0.5rem;
}
.account__delete-page .user__acount-form:focus {
     border: 1px solid var(--alpha-blue-back) !important;
     outline: none !important;
}
.account__delete-page .user__acount-form.user__account-reason {
    height: 200px !important;
}
.account__delete-page .account__delete-btn {
     width: auto;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 1rem;
     height: 50px;
     background-color: var(--alpha-blue-back);
     border: 1px solid var(--alpha-blue-back);
     border-radius: 8px;
     color: var(--alpha-white);
}
.account__delete-page .account__delete-btn:hover {
    background: var(--alpha-white);
    color: var(--alpha-blue-back);
    transition: background-color 0.3s ease-in;
} 
.user__delete-modal {
     min-height: 200px;
     display: flex;
     align-items: center;
     font-family: "Circular Std", sans-serif;
     color: var(--alpha-80);
}
.user__delete-modal .confirm-delete {
    display: flex;
    flex-direction: column;
    gap: 1rem;
     justify-content: center;
     align-items: center;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    text-align: center;
}
.user__delete-modal .confirm-delete .delete-actions {
    display: flex;
    gap: 1rem;
}
.user__delete-modal .confirm-delete .delete-actions button {
    padding: 1rem;
    border: none;
    border-radius: 5px;
}
.user__delete-modal .confirm-delete .delete-actions button:first-child {
    background: var(--alpha-blue-back);
    color: var(--alpha-white);
}
.user__delete-modal  {
    min-width: 340px;
}
.user__delete-modal .login__user-delete {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    position: relative;
}
.user__delete-modal .login__user-delete .cancel-login {
     position: absolute;
     right: 0;
     top: -0.5rem;
     cursor: pointer;
     font-size: 14px;
     color: var(--alpha-80);
}
.user__delete-modal .login__user-delete .login-inputs {
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
     width: 100%;
     font-family: "Circular Std", sans-serif;
}
.user__delete-modal .login__user-delete .login-inputs input {
     width: 100%;
     height: 50px;
     padding: 1rem;
     border-radius: 8px;
     outline: none;
     border: 1px solid var(--alpha-80);
}
.user__delete-modal .login__user-delete  button {
    width: 150px !important;
    height: 40px;
    background: var(--alpha-blue-back);
    color: var(--alpha-white);
    border: none;
}
.user__delete-modal .login__user-delete  button:hover {
     background: transparent;
     color: var(--alpha-blue-back);
     transition: all 0.3s ease;
}
.user__delete-modal .login__user-delete .cancel-login:hover {
     color: var(--alpha-red);
     transition: color 0.3s ease;
}
.user__delete-modal .login-header {
     padding: 0.75rem;
     color: var(--alpha-blue-back);
     /* font-weight: bold; */
}
@media only screen and (max-width: 280px){
    .account__delete-bg {
        height: 150px;
    }
    .account__delete-bg .account__name-display {
        margin-top: -2rem;
    }
    .account__delete-bg .account__name-display .account-title {
        font-size: 16px !important;
    }
    .account__delete-page .delete-form {
         width: 270px !important;
    }
    .user__delete-modal {
         min-width: 250px !important;
    } 
}

@media only screen and (min-width: 768px){

    .account__delete-bg .account__name-display .account-title {
        font-size: 28px !important;
    }
    .account__delete-page .delete-form {
         width: 450px !important;
    }
}
@media only screen and (min-width: 1024px){
    .account__delete-bg .account__name-display .account-title {
        font-size: 32px !important;
    }
}
@media only screen and (min-width: 1280px){
    .account__delete-bg .account__name-display .account-title {
        font-size: 36px !important;
     
    }
    .account__delete-page .delete-form {

        margin-top: -5.5rem;
   }
}