.home-page-alpha-search {
     width: 100%;
     display: flex;
     margin: auto;
     justify-content: center;
     align-items: center;
}
.home-page-alpha-search form {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.home-page-alpha-search form .search-field {
     width: 100% !important;
     position: relative;
}
.home-page-alpha-search .alpha__filtered-items {
    width: 98%;
    top: 58px;
    max-height: 400px;
    overflow-y: auto;
}
.home-page-alpha-search .alpha__filtered-items a {
     cursor: pointer;
     margin-top: 0.35rem;
     text-decoration: none;
     color: var(--alpha-80);
     font-size: 0.8rem;
     font-family: "Circular Std", sans-serif;
}
.home-page-alpha-search .alpha__filtered-items a:hover {
     color: var(--alpha-blue-back);
     transition: color 0.3s ease;
     font-weight: bold;
}
.home-page-alpha-search form input {
    width: 98%;
    height: 55px;
    background: #F5F5F5;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #524b4b;
    padding: 0 0.75rem;
}
.home-page-alpha-search form button.home-search-btn {
    width: 230px;
    height: 55px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    background: var(--alpha-blue-back);
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    letter-spacing: 0.5px;
    color: var(--alpha-white);
    border: none;
    outline: none;
    transition: gap 0.3s;
    position: relative;
}

.alpha-input .cancel__alpha-search {
    position: absolute !important;
    top: 50%;
    right: 1rem !important;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--alpha-80);
    font-weight: 400;
    transition: color 0.3s ease;
}
.alpha-input .cancel__alpha-search:hover {
    color: var(--alpha-red);
}

.home-page-alpha-search .home-search-btn .search-btn-icon {
    font-size: 20px !important;
}

.home-page-alpha-search form button.home-search-btn:hover {
       gap: 1.5rem;
       box-shadow: 2px 2px 10px rgba(97, 122, 88, 0.9);
}
.home-page-alpha-search form button.home-search-btn:hover::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-radius: 3px;
    border-top: 2px solid var(--alpha-success) !important;
    border-right: 2px solid var(--alpha-success) !important;
    animation: border-top-right 3s infinite alternate;
} 
.home-page-alpha-search form button.home-search-btn:hover::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-radius: 3px;
    border-bottom: 2px solid var(--alpha-success) !important;
    border-left: 2px solid var(--alpha-success) !important;
    animation: border-bottom-left 3s infinite alternate;
} 
.search-catalog {
    padding: 0 0 2rem 0;
    width: 100%;
    position: relative;
    margin: auto !important;
}
.search-catalog .catalog-section {
     width: 95%;
     margin: auto !important;
}
.search-catalog .catalog__items-count {
     color: var(--alpha-80);
     font-family: "Inter", sans-serif;
}

@keyframes border-top-right {
    0% {width: 0px; height: 0px}
    25% {width: 100%; height: 0px}
    50% {width: 100%; height: 100%}
    100% {width: 100%; height: 100%}
}
@keyframes border-bottom-left {
    0% {width: 0px; height: 0px; opacity: 0}
    50% {width: 0px; height: 0px; opacity: 0}
    50.1% {width: 0px; height: 0px; opacity: 0}
    75% {width: 100%; height: 0px; opacity: 1}
    100% {width: 100%; height: 100%; opacity: 1}
}

@media only screen and (min-width: 768px){
    .home-page-alpha-search form .search-field {
        width: unset !important;
    }
    .home-page-alpha-search .alpha__filtered-items {
         width: 100%;
    }
    .home-page-alpha-search form input {
        width: 381px;
    }
    .search-catalog {
        width: 95%;
    }
    .search-catalog .catalog-section {
        width: 100%;
   }
}
@media only screen and (min-width: 1280px){
    .search-catalog {
        max-width: 1080px !important;
    }
}