.promotions__details-page {
     padding: 0 0 2rem 0;
     width: 100%;
     position: relative;
 }
 .promotions__details-bg {
     background: url("../../../assets/store/banner.svg") no-repeat;
     background-size: cover;
     background-position: center;
     min-height: 200px;
     padding: 2rem 0;
     display: flex;
     align-items: center;
     width: 100%;
 
 }
 .promotions__details-bg  .promotions__details-container{
     width: 95%;
     display: flex;
     position: relative;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     height: 100%;
     margin: auto;
     padding: 1.5rem 0 !important;
     position: relative;
 }
 
 .promotions__details-bg  .back__to-page{
     display: flex;
     position: absolute;
     left: 0;
     top: -1rem;
     align-items: center;
     gap: 0.5rem;
     gap: 0.25rem;
     font-family: "Lato", sans-serif;
     font-size: 20px;
     font-weight: 500;
     color: var(--alpha-blue-back);
     cursor: pointer;
     
     
 }
 .promotions__details-bg  .promotions__details-container .promotions__name-display {
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 .promotions__details-bg .promotions__name-display .promotions-title {
     font-family: 'Rebond Grotesque', sans-serif;
     font-style: normal;
     font-weight: 800;
     font-size: 22px;
     letter-spacing: 0.5px;
     color: var(--alpha-blue-back);
 }
 .promotions__details-bg .promotions__name-display .promotions-breadcrumb span {
     color: var(--alpha-40) !important;
 }
 .promotions__details-bg .promotions__name-display .promotions-breadcrumb {
       font-family: "Circular Std", sans-serif;
       font-weight: 500;
       font-size: 14px;
 }
 .store_products  .search__promotions-list {
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
     width: 95%;
     margin: 3rem auto auto auto !important;
 }
 .store_products  .search__promotions-list form {
      width: 100% !important;
      position: relative;
      margin: auto !important;
 }
 .store_products  .search__promotions-list form input {
      width: 100%;
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      outline: none;
      border-bottom: 2px solid var(--alpha-40) !important;
      color: var(--alpha-80);
      border: none;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
 }
 .store_products  .search__promotions-list form input:focus {
     border-bottom: 2px solid var(--alpha-blue-back) !important;
     color: var(--alpha-blue-back);
     transition: border-bottom 0.3s ease-in;
 }
 .store_products  .search__promotions-list form .category__search-icon {
     position: absolute;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
     z-index: 2;
     color: var(--alpha-80);
 }

@media only screen and (max-width: 280px){
     .promotions__details-bg .promotions__name-display .promotions-title {
          font-size: 16px !important;
          margin-left: 0.5rem;
      }
      .promotions__details-bg .promotions__name-display .promotions-breadcrumb {
           font-size: 12px;
           margin-left: 0.5rem;
      }
}
@media only screen and (min-width: 768px){
     .promotions__details-bg .promotions__name-display .promotions-title {
          font-size: 28px;
      }
      .promotions__details-bg .promotions__name-display .promotions-breadcrumb {
            font-size: 18px;
      }
      .store_products  .search__promotions-list {
          width: 500px !important;
      }
}
@media only screen and (min-width: 1024px){
     .promotions__details-bg .promotions__name-display .promotions-title {
          font-size: 32px;
          line-height: 52px;
      }
}
@media only screen and (min-width: 1280px){
     .promotions__details-bg  .promotions__details-container,.promotions__details-page .promotions__filter-section,.promotions__details-page .promotions__detail-modal,.promotions__details-page .pagination__count-section  {
          max-width: 1080px !important;
    }
    .promotions__details-bg .promotions__name-display .promotions-title {
        font-size: 36px;
     
    }
}

