.price-area {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
}

.price-area .promoting-items {
      font-size: 0.75rem !important;
      font-weight: 500;
      color: var(--alpha-80);
      text-decoration-color: var(--alpha-red) !important;
      text-decoration: line-through !important;
}
 .format-price {
     font-size: 12px !important;
}
@media only screen and (min-width: 48em) {
    .price-area .promoting-items {
        font-size: 0.9rem !important;
    }
}