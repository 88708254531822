/* form {
    margin: 3rem auto;
    width: 500px;
    max-width: 80%;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  form > div {
    display: flex;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  }
  .input-group > label {
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }
  .input {
    width: 100%;
    display: flex;
  }
  .input > input {
    padding: 0.3rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  .input > input:focus {
    outline: none;
  }
  .input > div {
    padding: 0.5rem 1rem;
    background: #e34728;
    margin-left: 0.5rem;
    color: #fff;
    font-weight: 700;
    cursor: pointer; */
  /* } */
  .add-btn {
    padding: 0.5rem 1rem;
    background: #ccc;
    border: none;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .submit-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #e34728;
    background: #fff;
    margin: 1.2rem 0;
    color: #e34728;
    font-size: 0.9rem;
    font-weight: 700;
    cursor: pointer;
  }
  .dialog-box {
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    grid-gap: 1rem;
  }
  .dialog-box > input,
  .dialog-box > select {
    padding: 0.5rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .dialog-box > input:focus,
  .dialog-box > select:focus {
    outline: none;
  }
  .hide {
    display: none;
  }

  .check-category {
     position: absolute;
     height: 20px;
     width: 20px;
     border-radius: 5px;
     background: black;
     color: var(--alpha-white);
     z-index: 200;
     right: 6px;
     top: 0px;
     display: flex;
     justify-content: center;
     align-items: center;
  }
  


.upload-container {
    width: 95%;
    padding: 1rem 0;
    margin: auto !important;
}

.upload-section .upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter", sans-serif;
}
.upload-section .upload-header > div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.upload-section .upload-header > div:nth-child(1) > div:nth-child(2) {
     display: flex;
     align-items: center;
     gap: 0.25rem;
     max-width: 145px;
     border: 2px solid #C9FF7259;
     padding: 4px 8px;
     color: #7DC40A;
     background: #C9FF7259;
     font-size: 12px;
     border-radius: 8px;
}

.upload-section .upload-header > div:nth-child(1) > div:nth-child(1){
     font-size: 1rem;
     font-weight: 700;
     color: var(--alpha-80);
}

.upload-section .upload-header button{
    min-width: 150px;
    width: auto;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--alpha-blue-back);
    background: var(--alpha-blue-back);
    color: white;
    font-size: 12px;
}
.upload-section .upload-categories {
    height: 229px;
    border-radius: 10px;
    border: 1px solid var(--alpha-80);
    margin: 1rem auto;
    padding: 1rem;
    font-family: "Inter", sans-serif;
}

.upload-categories > div:nth-child(1){
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 13px;
      font-weight: 600;
      
}


.loader-container {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100%;
}
   
.category-items {
    margin-top: 1rem;
    position: relative;
    /* height: 229px; */
}

.category-items img {
    width: 104px;
    height: 104px;
    border-radius: 10px;
}
.category-items .active-category {
      border: 2px solid var(--alpha-blue-back);
      box-shadow: var(--alpha-shadow-sm);
}
.category-items .slides {
     display: flex;
     width: 100%;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 0.5rem;
     position: relative;
}
.category-items .slides span {
    font-family: "Inter", sans-serif;
    font-size: 0.64rem;
    text-align: center;
    font-weight: 500;
}

.category-items .controlled-slider {
     width: 100%;
     /* height: 100%; */
     position: relative;
}
.category-items .controlled-slider .category-control-btn {
     position: absolute;
     top: 52px;
     transform: translateY(-50%);
     z-index: 100;
     width: 36px;
     height: 36px;
     border-radius: 50%;
     border: none;
     box-shadow: var(--alpha-shadow-sm);
     color: var(--alpha-80);
     transition: all 0.3s ease-in-out;

}
.category-items .controlled-slider button.left{
     left: 0;
}
.category-items .controlled-slider button.right {
    right: 0;
}

.category-items .controlled-slider .category-control-btn:hover {
    background: var(--alpha-blue-back);
    color: var(--alpha-white);
}

.web-upload-media {
     width: 100%;
     max-width: 356px;
     min-height: 100%;
     height: auto !important;
     border: 1px solid rgba(51, 51, 51, 0.1);
     border-radius: 20px;
     padding: 1rem;
}
.web-product-description {
     display: flex;
     width: 711px;
     border: 1px solid rgba(51, 51, 51, 0.1);
     border-radius: 20px;
     padding: 1rem;
}

.image-form-up {
     font-family: "Inter", sans-serif;
     font-weight: 600;
     font-size: 16px;
}
.front-img-vid {
   height: 292px;
   width: 100%;
   background: rgba(247, 247, 247, 1);
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   align-items: center;
   justify-content: center;
   box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
   position: relative;
}
.front-img-vid .other-imgs {
     position: relative;
}
.front-img-vid #front-img,.other-imgs #left-img, .other-imgs #right-img, .other-imgs #rear-img, .video-upload-sec #video-upload {
     width: 100%;
     height: 100%;
     display: none;
     cursor: pointer;
}

.upload-label {
   font-family: "Inter", sans-serif;
   font-weight: 500;
   color: #9797AA;
   font-size: 12px;
}
.upload__vid_img-btn{
     width: 148px;
     height: 37px;
     color: #5C5C77;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     background: #FBFBFB;
     border: 1px solid #9797AA;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 0.25rem;
}
.other-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.other-imgs label {
     width: 93px;
     height: 93px;
     border-radius: 8px;
     background: #F7F7F7;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
}
.other-imgs label input {
     display: none;
}

.video-upload-sec {
    width: 100%;
    min-height: 100px;
    height: auto;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 8px;
    background: #F7F7F7;
    cursor: pointer;
    box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
    position: relative;
}
 
.upload-part-form {
     width: 100%;
}
.upload-part-form input {
     height: 48px;
     border-radius: 8px;
     box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
     padding: 0px 2.3rem 0px 0.75rem !important;
     background: rgba(247, 247, 247, 1);
     color: rgba(140, 140, 161, 1);
     border: 1px solid rgba(247, 247, 247, 1);
     outline: none;
}
.upload-part-form select {
    padding:  0px 0.25rem !important;
    height: 48px;
    border-radius: 8px;
    box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
    background: rgba(247, 247, 247, 1);
    color: rgba(140, 140, 161, 1);
    border: 1px solid rgba(247, 247, 247, 1);
    outline: none;
}
.upload-part-form label {
     color: rgba(92, 92, 119, 1);
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     margin-left: 2px;
}

.upload-part-form textarea {
     width: 100%;
     height: 132px;
     border-radius: 8px;
     border: 1px solid rgba(247, 247, 247, 1);
     box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
     background: rgba(247, 247, 247, 1);
     color: rgba(140, 140, 161, 1);
     padding: 0.5rem;
}
.upload-part-form textarea:focus-visible {
     outline: none;
}
.upload-part-form textarea::placeholder, .upload-part-form input::placeholder, .upload-part-form input::placeholder {
    font-size: 12px;
}
.upload-part-form .custom-divider {
      width: 100%;
      height: 1px;
      background: var(--alpha-10);
      margin: 0.25rem 0;
}

.upload-part-form .search__icon {
     position: absolute;
     top: 50%;
     right: 3px;
     transform: translateX(-50%);
     color: rgba(151, 151, 170, 1);
}
.upload-part-form .discount-apply {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     display: flex;
     margin-top: 1rem;
     align-items: start;
     justify-content: space-between;
}

.upload-part-form .discount-apply > div h3 {
     font-size: 12px;
     color: #5C5C77;
}
.upload-part-form .discount-apply > div p {
     font-size: 10px;
     color: #9797AA;
}



/* .upload-part-form .size-color-option {
     overflow-x: auto;
     flex-wrap: wrap;
} */

.upload-part-form .size-color-option {
  max-height: 300px;
  flex-wrap: wrap;
  transition: all 0.3s ease-in;
  overflow-y: auto;
}
.size-color-option .upload__size-button {
  width: auto !important;
  height: 40px !important;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  background: #F7F7F7;
  font-weight: 500;
  color: #262641;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.5rem;
}
.size-color-option .active__size-button {
  width: auto !important;
  height: 40px !important;
  border: none;
  border-radius: 5px;
  background: var(--alpha-blue-back);
  font-weight: 500;
  color: var(--alpha-white);
  display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.5rem;
   font-size: 12px;
}

.color-qty-size {
     display: flex;
     align-items: center;
     /* justify-content: space-between; */
     gap: 1rem;
}
.size-input {
     width: 73% !important;
     position: relative;
     box-sizing: border-box;
}
.color-qty-size input.qty-input[type=text] {
     width: 100%;
     padding: 0.5rem 0.5rem 0.5rem 3rem !important;
}
.add-colors {
     display: flex;
     align-items: center;
     gap:2px;
     cursor: pointer;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     /* width: 25% !important; */
}
.add-colors .add-btn-icon {
     border: 1px solid #9797AA;
     color: #9797AA;
     background: transparent;
     border-radius: 50%;
     height: 18px !important;
     width: 18px !important;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     box-sizing: border-box;
 

}
.add-colors  .add-text {
     color: #9797AA; 
     font-size: 10px;
}
.color-qty-size .size-btn-text {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      border: 1px solid var(--alpha-blue-back);
      background: var(--alpha-blue-back) !important;
      color: var(--alpha-white);
      box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
      position: absolute;
      top: 50%;
      left: 0.25rem;
      transform: translateY(-50%);
      font-size: 10px;
}

.gender-btn-sections .size-btn-active {
     height: 40px;
     width: auto;
     border-radius: 8px;
     border: none;
     background: var(--alpha-blue-back) !important;
     color: var(--alpha-white);
     box-shadow: 0px 2px 2px -1px inset rgba(74, 74, 104, 0.1);
     padding: 0.5rem;
 
}


.selected-color-style:nth-child(n+2) {
    margin-left: -15px;
}


.upload__color-button {
     width: 40px !important;
     height: 40px !important;
     border: none;
     border-radius: 5px;
     background: #F7F7F7;
     font-weight: 500;
     color: #262641;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
   }
    .active__color-button {
     width: 40px !important;
     height: 40px !important;
     border: none;
     border-radius: 5px;
     background: var(--alpha-blue-back);
     font-weight: 500;
     color: var(--alpha-white);
     display: flex;
      align-items: center;
      justify-content: center;
   }

   .color-palette {
     display: flex;
     flex-wrap: wrap;
   } 
   .color-palette-section {
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 0.35rem;
   } 
   .goto-btn {
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 14px;
        font-family: "Inter", sans-serif;
        background-color: var(--alpha-blue-back);
        color: var(--alpha-white);
        box-shadow: var(--alpha-shadow-sm);
        border-radius: 8px;
        transition: background-color 0.3s ease-in;
        border: none;
        outline: none;
   }
   /* .goto-btn:hover {
        background-color: #000000;
   } */
   .goto-btn.disable-goto {
      background-color: var(--alpha-40) !important;
      color: var(--alpha-80) !important;
      cursor: not-allowed !important;
   }
   .tablet-upload-media {
     width: 370px;
     min-height: 100%;
     height: auto !important;
     border: 1px solid rgba(51, 51, 51, 0.1);
     border-radius: 20px;
     padding: 1rem;
 }
 .mobile-upload-section {
     width: 100%;
 }
 .mobile-upload-media, .mobile-product-description {
     width: 370px;
     border: 1px solid rgba(51, 51, 51, 0.1);
     border-radius: 20px;
     padding: 1rem;
 }
 .mobile-upload-media {
      min-height: 100%;
      height: auto !important;
 }


 .gender-btn-sections {
       width: 100%;
       display: flex !important;
       gap: 1rem;
       padding: 0.5rem 0;
 }

 .gender-btn-sections button.btn-default {
     width: auto !important;
     height: 40px !important;
     border: none;
     border-radius: 5px;
     background: #F7F7F7;
     font-weight: 500;
     color: #262641;
     text-align: center;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0.5rem;

 }

 .autocomplete-dropdown-container{
      border: 1px solid var(--alpha-10);
      border-radius: 8px;
      margin: 0.125rem;
      padding: 1rem;
 }

 input.price-in-naira {
     padding-left: 1.3rem !important;
 }
 .naira-sign {
     position: absolute;
     top: 50% !important;
     transform: translateY(-50%);
     left: 0.5rem;
     font-size: 14px;
     font-weight: 600;
     color: var(--alpha-blue-back);
 }

 .upload-section .move__to-next {
      display: none;
      padding: 1rem 0;
 }

 .upload-section .move__to-next button {
      width: 100px;
      height: 40px;
      border: 1px solid var(--alpha-blue-back);
      box-shadow: var(--alpha-shadow-sm);
      background: var(--alpha-blue-back);
      border-radius: 5px;
      color: var(--alpha-white);
      font-size: 12px;
      font-weight: 700;
 }

 @media only screen and (max-width: 280px) {
     .mobile-upload-media, .mobile-product-description {
           width: 260px;
     }
 }
 @media only screen and (max-width: 375px) {
     .mobile-upload-media, .mobile-product-description {
           width: 330px;
     }
 }
@media only screen and (min-width: 48em){
      .upload-section .upload-header > div:nth-child(1){
          display: flex;
          flex-direction: initial;
          align-items: center;
          gap: 1rem;
      }
      /* .upload-section .upload-header button{ 
          width: 180px;
      } */
      .upload-part-form {
           width: 48%;
      }
      .tablet-upload-section {
           width: 100%;
      }
      .category-items img {
           width: 120px;
           height: 120px;
      }
}


@media only screen and (min-width: 1024px){
  .web-upload-section {
    width: 100%; 
   align-items: start;
   justify-content: space-between;
   gap: 2rem;
   
}
.upload-section .move__to-next {
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 1rem 0;
}

}
@media only screen and (min-width: 1280px) {
  
.web-upload-section {
        width: 1080px;
    }
    .web-product-description {
       width: 711px;
    }
    .category-items img {
        width: 110px;
        height: 110px;
    }
}


