
.alpha-video-post {
    border-radius: 5px;
    min-height: 400px;
    height: 100%;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #000;
}
.clickable {
    cursor: pointer;
}
.video-play {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}

.controls {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.controls .video-controls {
    font-size: 5em;
    color: var(--alpha-white);
    transition: opacity 200ms linear;
}
.control-shown {
    opacity: 1;
}
.control-hidden{
    opacity: 0;
}
.share-video {
    position: absolute;
    bottom: 3%;
    right: 5%;
    cursor: pointer;
    z-index: 9999999;
    font-size: 1.2em;
    color: var(--alpha-green);
}